<template>
    <div>
        <div v-if="mobile">
            <MobileLanding />
        </div>
        <div v-else>
            <Transition name="slide-left">
                <div
                     v-if="!loading"
                     id="panels"
                     :class="{'overflow-hidden': currentPanel != 0, 'overflow-y-auto': currentPanel == 0}">
                    <section
                             id="panel-start"
                             ref="panels"
                             class="panel flyout-item relative bg-no-repeat bg-right-top bg-cover" :class="{
                                 'steps': currentPanel != 0,
                                 'active': currentPanel != 0,
                                 'overflow-y-auto': currentPanel == 0
                             }">
                        <PanelStart
                                    v-if="currentPanel == 0"
                                    :data="introContent"
                                    :buttons="introButtons"
                                    @next-panel="nextPanel" />
                        <Transition name="fade">
                            <PanelOne
                                      v-if="currentPanel == 1"
                                      :data="introContent" />
                        </Transition>
                        <Transition name="fade">
                            <PanelTwo
                                      v-if="currentPanel == 2"
                                      :data="introContent" />
                        </Transition>
                        <Transition name="fade">
                            <PanelThree
                                        v-if="currentPanel == 3"
                                        :data="introContent" />
                        </Transition>
                        <PanelFooter
                                     v-if="currentPanel != 0"
                                     :currentPanel="currentPanel"
                                     :data="introContent"
                                     :buttons="introButtons"
                                     @next-panel="nextPanel" />
                    </section>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PanelStart from "../components/intro/PanelStart.vue";
import PanelOne from "../components/intro/PanelOne.vue";
import PanelTwo from "../components/intro/PanelTwo.vue";
import PanelThree from "../components/intro/PanelThree.vue";
import PanelFooter from "../components/intro/PanelFooter.vue";
import MobileLanding from '../components/mobile/MobileLanding.vue';


export default {
    name: 'IntroPanels',
    computed: {
        ...mapGetters({
            introContent: 'global/intro_content',
            pageContent: 'global/ui_text',
            currentPanel: 'global/intro_current_panel',
            loading: 'records/loading',
            mobile: 'global/mobile',
        }),
        introButtons() {
            if (this.pageContent.intro) {
                return this.pageContent.intro.buttons;
            } else {
                return {};
            }
        },
        introCopyright() {
            if (this.pageContent.intro) {
                return this.pageContent.intro.copyright;
            } else {
                return '';
            }
        },
    },
    components: {
    PanelStart,
    PanelOne,
    PanelTwo,
    PanelThree,
    PanelFooter,
    MobileLanding,
    // MobileTimeline,
},
    data: function () {
        return {
            title: 'Timeweb IntroPanels'
        }
    },
    methods: {
        nextPanel() {
            let next = this.currentPanel + 1;
            this.$store.dispatch('global/setCurrentPanel', next)
        }
    },
    head: {
        title: function () {
            return {
                inner: this.title
            }
        },
        meta: [
            { name: 'description', content: 'This is the intro page for Time web', id: 'desc' }
        ],
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('global/setCurrentPanel', 0)
        })
    }
}
</script>

