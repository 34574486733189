<template>
    <div class="filter-wrapper keyword">
        <div class="column">
            <h3 class="label"><span class="text">{{ content.keyword_header }}</span></h3>
        </div>
        <div class="column">
            <div class="wrapper add-keyword mb-2.5">
                <form class="form-add-keyword" @submit="addKeyword($event)">
                    <label class="sr-only">{{ content.buttons.add_keyword }}</label>
                    <input type="text" v-model="newWord" :placeholder="content.keyword_placeholder" />
                        <span class="sr-only">{{ content.buttons.add_keyword }}</span>
                    <button class="add">
                        <IconKeyword />
                    </button>
                </form>
            </div>
            <div class="wrapper options">
                <Tag v-for="word in keywords"
                    :key="word"
                    :selected="true"
                    @x-action="removeKeyword(word)">
                    {{ word }}
                </Tag>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Tag from "../buttons/Tag.vue";
import IconKeyword from "../icons/IconKeyword.vue";

export default {
    name: 'FilterKeywords',
    components: {
        Tag,
        IconKeyword,
    },
    data () {
        return {
            newWord: '',
        }
    },
    computed: {
        ...mapGetters({
            'keywords': 'timeline/keywords',
            'ui_text': 'global/ui_text',
        }),
        content () {
            return this.ui_text.filters;
        }
    },
    methods: {
        addKeyword (e) {
            e.preventDefault();
            this.$store.dispatch('timeline/addKeyword', this.newWord);
            this.newWord = '';
        },
        removeKeyword (word) {
            this.$store.dispatch('timeline/removeKeyword', word);
        },
    },
}
</script>
