<template>
    <main>
        <div v-if="mobile">
            <MobileTimeline />
        </div>
        <div v-else>
            <Transition name="fade">
                <div v-if="loading" id="overlay">
                    <div class="loading">
                        <h1>Loading...</h1>
                    </div>
                    <IconSpinner />
                </div>
            </Transition>
            <div id="timeline">
                <TopicsThemes />
                <div ref="scroll" @mousemove="dragScroll" @mousedown="setPos" @mouseup="stopDrag"
                     class="inner overflow-y-hidden overflow-x-auto w-[100%] min-w-[100%]"
                     :class="{ 'pr-[670px]': detailOpen }">
                    <section class="timeline-entries" aria-labelledby="results-title">
                        <Entries />
                    </section>
                    <button class="milestone-toggle"
                            aria-controls="milestone-section"
                            aria-pressed="milestonesOpen"
                            :class="{ 'expanded': milestonesOpen }"
                            @click="toggleMilestoneTimeline">
                        <IconDropdown :class="{ 'rotate-180': milestonesOpen }" />
                        <span class="sr-only">Toggle curated timeline display</span>
                        <span v-if="!milestonesOpen" class="ml-2">
                            Compare your timeline to <u>{{ selectedTimeline.title }}</u>
                        </span>
                    </button>
                    <div class="timeline-milestones" :class="{ 'active': milestonesOpen }">
                        <div class="milestone-table size-100">
                            <YearsTimeline @scrollToCenter="centerTl" />
                            <Milestones :open="milestonesOpen" @scrollToCenter="centerTl" />
                        </div>
                    </div>
                    <TimelineTitle v-if="milestonesOpen" />
                </div>
                <Tool />
                <Transition name="fade" mode="out-in">
                    <button
                            key="left"
                            v-if="showLeftArrow"
                            class="fixed bottom-[38%] disabled:opacity-[.5] left-[75px]"
                            :disabled="checkNextEntry('last')"
                            :aria-hidden="!showLeftArrow"
                            @click="scrollTo('last')">
                        <span class="sr-only">Go to last event</span>
                        <IconArrowLeft />
                    </button>
                </Transition>
                <Transition name="fade" mode="out-in">
                    <button
                            v-if="showRightArrow"
                            key="right"
                            class="fixed bottom-[38%] disabled:opacity-[.5] right-[25px]"
                            :disabled="checkNextEntry('next')"
                            :aria-hidden="!showRightArrow"
                            @click="scrollTo('next')">
                        <span class="sr-only">Go to next event</span>
                        <IconArrowRight />
                    </button>
                </Transition>
            </div>
            <ShareModal />
        </div>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'

import Tool from "../components/timeline/Tool.vue";
import TopicsThemes from "../components/timeline/TopicsThemes.vue";
import ShareModal from "../components/timeline/ShareModal.vue";
import Entries from "../components/timeline/Entries.vue";
import Milestones from "../components/timeline/Milestones.vue";
import YearsTimeline from "../components/timeline/YearsTimeline.vue";
import TimelineTitle from "../components/buttons/TimelineTitle.vue";
import IconDropdown from "../components/icons/IconDropdown.vue";
import IconArrowLeft from "../components/icons/IconArrowLeft.vue";
import IconArrowRight from "../components/icons/IconArrowRight.vue";
import IconSpinner from '@/components/icons/IconSpinner.vue';
import MobileTimeline from '../components/mobile/MobileTimeline.vue'

export default {
    name: 'TimelineView',
    components: {
        Tool,
        TopicsThemes,
        ShareModal,
        Entries,
        Milestones,
        YearsTimeline,
        TimelineTitle,
        IconDropdown,
        IconArrowLeft,
        IconArrowRight,
        IconSpinner,
        MobileTimeline,
    },
    data() {
        return {
            showArrows: false,
            observer: null,
            pos: {},
            dragging: false,
        }
    },
    computed: {
        ...mapGetters({
            milestonesOpen: 'global/milestones_open',
            selectedTimeline: 'global/selected_curated_timeline',
            centerYear: 'timeline/centerYear',
            populatedYears: 'timeline/populatedYears',
            loading: 'records/loading',
            detail: 'records/detail',
            mobile: 'global/mobile',
        }),
        detailOpen() {
            return Object.keys(this.detail).length > 0;
        },
        showLeftArrow() {
            return this.centerYear > 100;
        },
        showRightArrow() {
            const year = new Date().getFullYear();
            return this.centerYear < year - 75;
        },
    },
    watch: {
        detail(newDetail) {
            if (!newDetail.date) return;
            let yr = newDetail.date.match(/(?:[0-9]{4})/g) ? newDetail.date.match(/(?:[0-9]{4})/g)[0] : 0;
            let nextEle = document.querySelector(`#yr-${yr}`)
            if (nextEle && yr != 0) {
                let newPos = nextEle.offsetLeft - window.innerWidth / 3
                this.centerTl(newPos, 'smooth')
            }
        }
    },
    methods: {
        toggleMilestoneTimeline() {
            this.$store.dispatch('global/milestonesToggle')
        },
        centerTl(x, behavior = 'instant') {
            this.$refs.scroll.scrollTo({
                top: 0,
                left: x,
                behavior: behavior
            })
        },
        nextEntry(dir) {
            let nextYear = null;
            if (dir == 'next') {
                nextYear = this.populatedYears.find(y => {
                    return y > this.centerYear + 2;
                })
            } else {
                nextYear = this.populatedYears.filter(y => {
                    return y < this.centerYear - 1;
                }).pop()
            }

            return nextYear;
        },
        checkNextEntry(dir) {
            return !this.nextEntry(dir);
        },
        scrollTo(dir) {
            let nextYear = this.nextEntry(dir) || 1800;
            let nextEle = document.querySelector(`#yr-${nextYear}`)
            let newPos = nextEle.offsetLeft - window.innerWidth / 2;

            this.centerTl(newPos, 'smooth')
        },
        setPos(e) {
            this.dragging = true;
            this.pos = {
                left: this.$refs.scroll.scrollLeft,
                x: e.clientX,
            };
        },
        stopDrag() {
            this.dragging = false;
        },
        dragScroll(e) {
            if (!this.dragging) return;
            const dx = e.clientX - this.pos.x;
            let newL = this.pos.left - dx;

            this.$refs.scroll.scrollTo({
                top: 0,
                left: newL,
                behavior: 'instant'
            })
        }
    },
    mounted() {
        this.$store.dispatch('global/setShowLearnTooltip', true);
        setTimeout(() => {
            this.$store.dispatch('global/setShowLearnTooltip', false);
        }, 4500)

        this.entriesObserver = new IntersectionObserver((entries) => {

            entries.forEach(group => {
                if (group.isIntersecting) {
                    group.target.classList.add('in-view')
                } else {
                    group.target.classList.remove('in-view')
                }
            })

            let anyVisible = entries.find(e => e.isIntersecting)
            let inViewEntries = document.querySelectorAll('.in-view');

            if (!anyVisible) {
                if (!inViewEntries.length) {
                    this.showArrows = true;
                }
            } else {
                if (this.showArrows) {
                    this.showArrows = false;
                }
            }
        }, {
            threshold: [0],
            rootMargin: '0px 0px 0px 0px'
        });

        this.emitter.on("mounted-groups", ele => {
            this.entriesObserver.observe(ele);
        })
    },
}

</script>