import { createApp } from 'vue'
import VueAnnouncer from '@vue-a11y/announcer'
// import VueHead from 'vue-head'
import '@vue-a11y/announcer/dist/style.css'
import 'vue-select/dist/vue-select.css';

import App from './App.vue'
import router from './router'
import store from './store'
import './index.css';
import vSelect from 'vue-select'


const app = createApp(App)

import mitt from 'mitt';
const emitter = mitt();

app.use(router)
app.use(store)
// app.use(VueHead)
app.use(VueAnnouncer, {router})
app.mount('#app')
app.component('v-select', vSelect)
app.config.globalProperties.emitter = emitter;
