<template>
  <div>
    <VueAnnouncer class="sr-only"/>
    <NavBar v-if="!mobile"/>
    <Transition name="slide-left" mode="out-in">
      <FilterPanel v-if="showFilters" />
    </Transition>
    <Transition name="slide-right" mode="out-in">
      <AboutPanel v-if="showAbout" />
    </Transition>

    <router-view name="flyout" v-slot="{ Component }">
      <Transition name="slide-left" mode="out-in">
        <component :is="Component"></component>
      </Transition>
    </router-view>
    <router-view name="timeline"></router-view>
    <router-view name="detail" v-slot="{ Component }" :record="currentRecord">
      <Transition name="slide-right" mode="out-in">
        <component :is="Component" v-if="!loading"></component>
      </Transition>
    </router-view>
    <BgOverlay v-if="showOverlay" />
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { initJsStore } from "./service/idb_service";

import NavBar from './components/NavBar';
import FilterPanel from './components/Filters';
import AboutPanel from './components/AboutPanel'
import BgOverlay from './components/BgOverlay'

export default {
  name: 'App',
  components: {
    NavBar,
    FilterPanel,
    AboutPanel,
    BgOverlay,
  },
  computed: {
    ...mapGetters({
      'showFilters': 'global/filter_panel_open',
      'showAbout': 'global/about_panel_open',
      'currentRecord': 'records/detail',
      'loading': 'records/loading',
      'search_obj': 'timeline/search_obj',
      'ui_text': 'global/ui_text',
      'mobile': 'global/mobile',
    }),
    showOverlay() {
      if (this.mobile) {
        return false
      }
      let isInitialLoading = this.$route.name === 'landing-page' && !this.loading;
      return isInitialLoading || this.showAbout || this.showFilters;
    }
  },
  async beforeCreate() {
    try {
      const createdNewDb = await initJsStore();
      let needsUpdate = true;
      const lastUpdated = localStorage.getItem('ohstw-last-update');
      if (lastUpdated) {
        const lastUpdate = new Date(lastUpdated);
        const now = new Date();
        needsUpdate = lastUpdate.getTime() + 86400000 < now.getTime()
      }
      const callAPI = createdNewDb || needsUpdate;
      this.$store.dispatch('global/checkIfMobile');
      this.$store.dispatch('global/fetchUIText');
      this.$store.dispatch('global/useCache', !callAPI);
      this.$store.dispatch('global/fetchEras');
      this.$store.dispatch('filters/fetchSubjects');
      this.$store.dispatch('filters/fetchRegions');
      await this.$store.dispatch('records/initResults');
      await this.$store.dispatch('global/fetchCuratedTimelines');
    } catch (ex) {
      console.error(ex);
    }
    if (Object.keys(this.$route.query).length) {
      await this.$store.dispatch('timeline/saveUrlQuery', this.$route.query);
      this.$store.dispatch('records/search', this.search_obj);
    } else {
      this.$store.dispatch('records/search');
    }
    if (this.$route.name == 'detail') {
      this.$store.commit('timeline/setZoomLevel', 4)
    }
  },
  async mounted() {
    await this.$store.dispatch('global/fetchIntroContent');

    // Check if running on Kiosk Pro
    const bodyId = document.querySelector('body').id
    if(bodyId === 'isKiosk-timeweb'){
      this.$store.commit('global/setIsKiosk', true)
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
}
</style>
