<template>
    <a :href="href" class="read-more" target="_blank">
        <span class="text"><slot></slot></span>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.35602 2.34873L8.34277 2.33683L3.60685 7.07275L4.66751 8.13341L9.50623 3.29469L9.49352 6.48624L10.9935 6.49221L11.0131 1.58217L11.0161 0.826172L10.2601 0.829184L5.35004 0.848746L5.35602 2.34873ZM2.4834 1.53134V9.58421H10.5363V11.0842H1.7334H0.983398V10.3342V1.53134H2.4834Z" fill="#A28707"/>
        </svg>    
    </a>
</template>

<script>

export default {
    name: 'ReadMoreButton',
    props: {
        href: {
          type: String,
          required: true,
        }
    },
}
</script>