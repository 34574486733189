<template>
    <div v-if="shareModalOpen" class="timeline-share z-[10]">
        <div class="inner">
            <div class="modal bg-neutral-10 p-[3.125rem]">
                <button ref="closeBtn" @click="toggleShareModal" class="close-modal">
                    <IconClose />
                    <span class="sr-only">{{ content.buttons.close }}</span>
                </button>
                <h2>{{ content.header }}</h2>
                <p>{{ shareText }}</p>
                <div v-if="isKiosk" class="qr-code flex justify-center pt-[3.125rem]">
                    <qrcode-vue :value="shareUrl" :size="300"/>
                </div>
                <form v-else class="form-share button-wrap">
                    <label class="sr-only">Timeline url</label>
                    <input ref="shareUrl" readonly type="text" :value="shareUrl" />
                    <button type="button" @click="copyUrl" class="button btn-gold"><span class="text">{{ content.buttons.copy }}</span></button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import IconClose from "../icons/IconClose.vue";

export default {
    name: 'ShareModal',
    components: {
        IconClose,
        QrcodeVue,
    },
    computed: {
        ...mapGetters({
            shareModalOpen: 'global/share_modal_open',
            pageContent: 'global/ui_text',
            shareUrl: 'global/share_url',
            isKiosk: 'global/is_kiosk',
        }),
        content() {
            if (!this.pageContent.share) return {}
            return this.pageContent.share
        },
        shareText() {
            if (this.isKiosk) {
                return this.content?.qr_text;
            }
            return this.content?.text;
        },
    },
    watch: {
        shareModalOpen(newState) {
            if (newState) {
                this.$nextTick(() => {
                    this.$refs.closeBtn.focus()
                })
            }
        }
    },
    methods: {
        toggleShareModal() {
            this.$store.dispatch('global/shareModalToggle');
        },
        copyUrl() {
            this.$refs.shareUrl.focus()
            this.$refs.shareUrl.select()
            this.saveToClipboard(this.shareUrl)
        },
        saveToClipboard(text) {
            // using navigator clipboard api 
            // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/write

            const type = "text/plain";
            const blob = new Blob([text], { type });

            // eslint-disable-next-line
            const data = [new ClipboardItem({ [type]: blob })];
            navigator.clipboard.write(data);
        }
    },
}
</script>
