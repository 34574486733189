<template>
    <div id="panel-1" class="active inner pt-[5rem] sh-lg:pt-[1rem] pl-[8rem] pr-[6.938rem]">
        <div class="wrapper flex flex-wrap justify-between">
            <div class="column basis-[27.1rem] shrink-0 grow-0">
                <h2 class="heading text-[3rem] font-proxima text-secondary-10 font-light leading-none mb-[1.688rem]">
                    {{ data.page_1_title }}</h2>
                <div class="text-wrap">
                    <p class="mb-3.5 text-white">{{ data.page_1_text }}</p>
                </div>
            </div>
        </div>
        <div
             class="timeline-scatter-images absolute top-0 right-0 scattered w-[calc(100%-300px)] lg:w-[52.5rem]">
            <div v-for="(image, index) in data.display_images" :key="image.id" class="image"
                 :class="positionClasses[index]">
                <img
                     :src="image.image_url"
                     :alt="image.title" />
                <span class="text">
                    <span>{{ image.title }}<br>{{ image.item_number }}</span>
                </span>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "PanelOne",
    props: { data: Object },
    data() {
        return {
            positionClasses: [
                'left-0 lg:right-[35.375rem] -top-[28.313rem] lg:-top-[15.313rem] rotate-[7.97deg]',
                'left-[10.688rem] lg:right-[20.188rem] -top-[10.563rem] lg:-top-[3.25rem] -rotate-[17.42deg]',
                '-right-[0.188rem] lg:-right-[1.875rem] -top-[7.125rem] lg:-top-[6.25rem] rotate-[10.5deg]',
                'right-0 lg:right-[12.875rem] -top-[2.563rem] lg:top-[5.063rem] -rotate-[55.53deg]',
                '-right-[9.313rem] lg:right-0 -top-[18rem] md:-top-[4.875rem] lg:top-[6.25rem] rotate-[9.01deg]',
                '-right-[1.25rem] lg:right-[10.688rem] -top-[14.125rem] lg:top-[16.125rem] lg:top-[18rem] rotate-[11.72deg]',
                '-right-[10.188rem] lg:-right-[3.938rem] top-[19.688rem] lg:top-[20.875rem] -rotate-[9.21deg]',
                '-right-[6.375rem] lg:-right-[1.25rem] top-[1.438rem] lg:top-[29.438rem] lg:top-[30.25rem] -rotate-[1.97deg]'
            ]
        }
    },
}
</script>