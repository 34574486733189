<template>
    <div class="filter-wrapper topics">
        <div class="column">
            <h3 id="topics-selection-title" class="label flex">
                <span class="text">{{ header }}</span>
                <span class="count" v-if="subjCount">{{ subjCount }}</span>
            </h3>
        </div>
        <div class="column">
            <div class="wrapper options" role="list" aria-labelledby="topics-selection-title">
                <Tag v-for="subject in subjects"
                    :key="subject.localId"
                    @click-action="toggleSubject(subject.localId)" 
                    @x-action="removeSubject(subject.localId)"
                    :selected="isSelectedSubject(subject.localId)">
                    {{ subject.title }}
                </Tag>
            </div>
        </div>
    </div>
</template>

<script>
import Tag from "../buttons/Tag.vue";
import { mapGetters } from 'vuex'


export default {
    name: 'FilterTopics',
    components: {
        Tag,
    },
    props: {
        header: String,
    },
    computed: {
        ...mapGetters({
            selectedSubjects: 'timeline/selected_subjects',
            selectedTopics: 'timeline/selected_topics',
            selectedThemes: 'timeline/selected_themes',
            subjects: 'filters/subjects',
        }),
        subjCount () {
            return this.selectedTopics.length + this.selectedThemes.length;
        }
    },
    methods: {
        toggleSubject (subject) {
            if (subject.includes('theme')) {
                if (this.isSelectedSubject(subject)) {
                    this.$store.dispatch('timeline/removeSelectedTheme', subject)
                } else {
                    this.$store.dispatch('timeline/addSelectedTheme', subject)
                }
            } else {
                if (this.isSelectedSubject(subject)) {
                    this.$store.dispatch('timeline/removeSelectedTopic', subject)
                } else {
                    this.$store.dispatch('timeline/addSelectedTopic', subject)
                }
            }
        },
        removeSubject(subject) {
            if (subject.includes('theme')) {
                this.$store.dispatch('timeline/removeSelectedTheme', subject)
            } else {
                this.$store.dispatch('timeline/removeSelectedTopic', subject)
            }
        },
        isSelectedSubject(subject) {
            return this.selectedSubjects.includes(subject);
        }
    }
}
</script>