<template>
<span :aria-hidden="tabable == -1">
    <a v-if="record.slug" href="#" 
      class="milestone" 
      :tabindex="tabable"
      @click="openDetail"
      :class="{'bottom': record.listOrder % 2 == 0}">
        <span class="title">{{ title }}</span>
        <span class="image">
            <img :alt="title" :src="`https://${detail.domain_name}${detail.thumbnail_url}`">
        </span>
        <span class="shape top"><TooltipTop /></span>
        <span class="shape bottom"><TooltipBottom /></span>
    </a>
    <span v-else 
       class="milestone world-event cursor-default"
       :class="{'bottom': record.listOrder % 2 == 0}">
        <span class="title">{{ record.title }}</span>
        <span class="shape top"><TooltipTop color="#44535C" /></span>
        <span class="shape bottom"><TooltipBottom color="#44535C" /></span>
    </span>
</span>
</template>

<script>
import { mapGetters } from 'vuex'

import TooltipTop from "../icons/TooltipTop.vue";
import TooltipBottom from "../icons/TooltipBottom.vue";
import { connection } from "../../service/jsstore_con.js";

export default {
    name: 'MilestoneButton',
    components: {
        TooltipTop,
        TooltipBottom,
    },
    props: {
        record: {
            type: Object
        },
        stacked: Boolean
    },
    data () {
        return {
            detail: {}
        }
    },
    computed: {
        ...mapGetters({
            'search_string': 'timeline/search_string',
            milestonesOpen: 'global/milestones_open',
        }),
        slug () {
            return this.detail.slug.split('/').reverse()[0] 
        },
        title () {
            let t = this.record.title ? this.record.title : this.detail.title;
            return t.length > 45 ? `${t.slice(0,45)}...` : t;
        },
        tabable () {
            return this.milestonesOpen ? 0 : -1;
        }
    },
    async mounted () {
        let search = {
            from: 'Entries',
            where: {
                slug: this.record.slug,
            }
        }
        
        if (this.record.slug.length) {
            await connection.select(search).then(results => {
                if (results.length > 0) {
                    let detail = results[0];
                    if (this.record.title) detail.display_title = this.record.title;
                    this.detail = detail;
                }
            })
        }
    },
    methods: {
        openDetail () {
            if (!this.record.slug || this.stacked ) return;
            this.$store.dispatch('records/setSelectedRecord', this.detail)
            this.$store.dispatch('global/closeAllPanels');
            this.$router.push(`/timeline/${ this.detail.site_name }/${ this.slug }${this.search_string}`);
        }
    }
}
</script>