<template>
    <div class="timeline-title">
        <v-select 
            label="title" 
            :options="timelines"
            :v-model="selectedTl"
            :searchable="false"
            :clearable="false"
            :placeholder="selectedTimeline.title"
            :appendToBody="true"
            :calculate-position="withPopper"
            @option:selected="setSelected"
            class="min-w-[282px] h-[46px]">
            <template v-slot:option="option">
                {{ option.title }}
            </template>
            <template #selected-option="option">
                <span class="underline">{{ option.title }}</span>
            </template>
            <template #open-indicator="{ attributes }">
                <IconDropdown v-bind="attributes" class="rotate-180"/>
            </template>
        </v-select>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { createPopper } from '@popperjs/core'
import IconDropdown from "../icons/IconDropdown.vue";

export default {
    name: "TimelineDropdown",
    data () {
        return {
            selectedTl: null,
            placement: 'top', 
        }
    },
    components: {
        IconDropdown,
    },
    computed: {
        ...mapGetters({
            'timelines': 'global/curated_timelines',
            'selectedTimeline': 'global/selected_curated_timeline',
        }),
    },
    methods: {
        setSelected(value) {
            this.selectedTl = value;
            this.$store.dispatch('global/setCuratedTimeline', value)
        },
        withPopper(dropdownList, component, { width }) {
            dropdownList.style.width = width

            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: this.placement,
                modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 10],
                    },
                },
                {
                    name: 'toggleClass',
                    enabled: true,
                    phase: 'write',
                    fn({ state }) {
                        component.$el.classList.toggle(
                            'drop-up',
                            state.placement === 'top'
                        )
                    },
                },
                ],
            })

            return () => popper.destroy()
        },
    },
    mounted () {
        this.selectedTl = this.timelines[0];
    }
}
</script>


<style scoped>
* {
  --vs-controls-color: #3D9E98;
  --vs-border-color: #3D9E98;
  --vs-border-radius: 15px;
  --vs-search-input-placeholder-color: #FFFFFF;

  --vs-selected-color: #FFFFFF;
}

.v-select >>> .vs__dropdown-toggle {
    height: 100%;
}
.v-select >>> .vs__search {
    padding-left: 1rem;
}

.v-select >>> .vs__actions {
    padding-top: .125rem;
    padding-right: 1rem;
    transition: transform 300ms ease-in;
    transform-origin: center;
}
.v-select.vs--open >>> .vs__actions {
    transform: rotateX(180deg);
}

.v-select.drop-up.vs--open >>> .vs__dropdown-toggle {
  border-radius: 15px !important;
  border-bottom: solid 1px #3D9E98 !important;
  outline: none;
}

.v-select.vs--single >>> .vs__selected {
    opacity: 1;
    position: relative;
}

.v-select >>> .vs__open-indicator {
    fill: none;
}

.v-select >>> input.vs__search::placeholder {
    text-decoration: underline;
}

</style>