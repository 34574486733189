<template>
    <li :id="`yr-${year}`" ref="yearNum" :aria-hidden="!showYear">
        <span class="text" :class="{'whitespace-nowrap text-base': isTI}" v-if="showYear">{{ displayText }}</span>
        <span v-else-if="showLine" class="divider"></span>
    </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'YearSections',
    props: {
        year: Number,
        showYear: Boolean,
        showLine: Boolean,
        isTI: Boolean,
        text: String,
    },
    computed: {
        ...mapGetters({
            eras: 'timeline/tl_eras',
            centerYear: 'timeline/centerYear',
            'zoom_level': 'timeline/zoom_level',
        }),
        displayText () {
            return this.isTI ? this.text : this.year;
        }
    },
    mounted () {
        this.observer = new IntersectionObserver( ([entry]) => {
            if (entry && entry.isIntersecting) {
                this.$store.dispatch('timeline/setCenterYr', this.year);
            }
            // entry.target.style.backgroundColor = entry.isIntersecting ? "blue" : "orange"
        }, {
            threshold: [0.5],
            rootMargin: '0px -45% 0px -45%'
        });

        this.observer.observe(this.$refs.yearNum)
    },
    beforeUnmount () {
        if (this.observer) this.observer.unobserve(this.$refs.yearNum)
    },
}
</script>