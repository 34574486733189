<script>
import { mapGetters } from 'vuex';

import Logo from "../icons/IconLogo.vue";
import MobileEntry from "./MobileEntry.vue";
// import Tag from "../buttons/Tag.vue";

export default {
    name: 'MobileTimeline',
    components: {
        Logo,
        MobileEntry,
        // Tag,
    },
    computed: {
        ...mapGetters({
            selectedTimeline: 'global/selected_curated_timeline',
            content: 'records/mobileRecords',
        }),
    },
    data(){
        return{
            entires: []
        }
    },
    async beforeCreate() {
        await this.$store.dispatch('records/getMobileRecords')
        const uniqueEntries = [...new Map(this.content.map((entry) => [entry.id, entry])).values()];
        const sorted = uniqueEntries.sort((a, b) => (a.date > b.date ? 1 : -1))
        this.entires = sorted;
    },
}
</script>

<template>
    <div v-if="this.$route.name == 'timeline'" id="timeline-view" class="view">
        <div class="inner">
            <header>
                <div class="wrapper px-7 py-[1.125rem]">
                    <span class="logo mr-2.5">
                        <Logo />
                    </span>
                    <span class="text">Oregon History Project | TimeWeb</span>
                </div>
            </header>
            <!-- TODO add this back if sharing timelines on mobile -->
            <!-- <div
                 class="wrapper topics-themes flex flex-wrap gap-[0.313rem] pb-[1.25rem] mb-[1.875rem] border-b border-b-neutral-90">
                <Tag href="#" class="alt">Tags are hard coded</Tag>
                <Tag href="#" class="alt">Women</Tag>
                <Tag href="#" class="alt">Sports</Tag>
            </div> -->
            <div class="wrapper results grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-[2rem] mt-5">
                <MobileEntry v-for="(entry) in entires" :key="entry.id" :href="`https://${entry.domain_name}${entry.url}`">
                    <template #year>{{ entry.date }}</template>
                    <template #image>
                    <img :src="`https://${entry.domain_name}${entry.image_url}`" :alt="entry.title" />
                    </template>
                    <template #title>{{ entry.title }}</template>
                    <template #excerpt>{{entry.teaser}}</template>
                </MobileEntry>
            </div>
        </div>
    </div>
</template>