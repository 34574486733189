<template>
    <article class="timeline-entry">
        <span class="year">
            <slot name="year"></slot>
        </span>
        <a href="#" class="image">
            <slot name="image"></slot>
        </a>
        <h2 class="title"><a :href="this.$attrs.href" target="_blank">
                <slot name="title"></slot>
            </a></h2>
        <p class="excerpt line-clamp">
            <slot name="excerpt"></slot>
        </p>
    </article>
</template>

<style scoped>
.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    hyphens: auto;
}
</style>