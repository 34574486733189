<template>
    <section
             :key="`detail-${record.id}`"
             class="timeline-entry-detail !z-[4]"
             :class="{ 'expanded': imageExpanded }"
             aria-labelledby="detail-title">
        <h1 id="detail-title" class="sr-only">Entry detail</h1>
        <div class="inner">
            <router-link
                         tabindex="0"
                         :to="`/timeline${search_string}`"
                         class="close-entry"
                         @keyup.space="navigate(`/timeline${search_string}`)">
                <IconClose />
                <span class="sr-only">{{ buttonText.close }}</span>
            </router-link>

            <div class="image-wrap mb-[3.125rem]">
                <div class="image cursor-pointer" @click="toggleImageZoom">
                    <img :alt="record.title" :src="`https://${record.domain_name}${record.image_url}`" />
                    <span class="magnify">
                        <span class="expand">
                            <IconExpand />
                            <span class="sr-only">{{ buttonText.img_zoom_in }}</span>
                        </span>
                        <span class="collapse">
                            <IconCollapse />
                            <span class="sr-only">{{ buttonText.img_zoom_out }}</span>
                        </span>
                    </span>
                </div>
            </div>
            <span v-if="!imageExpanded && record.year !== 'TI'" class="year">{{ formatYear(year) }}</span>
            <div class="details">
                <span v-if="displayTitle" class="sub-title mb-[0.438rem]">
                    {{ record.display_title }}
                </span>
                <h2 id="record" class="title mb-5">{{ record.title }}</h2>
                <p
                   v-if="!imageExpanded"
                   v-html="previewText"
                   class="excerpt mb-[1.625rem] line-clamp">
                </p>
                <div v-if="!imageExpanded && !is_kiosk" class="wrapper mb-12">
                    <ReadMore :href="siteUrl">Read More on {{ siteName }}</ReadMore>
                </div>
                <div v-if="!imageExpanded" class="wrapper flex flex-wrap gap-x-[0.938rem] gap-y-[0.625rem]">
                    <template v-for="theme in subjects" :key="theme">
                        <EntryFilter href="#" :class="{ 'selected': isSelected(theme) }">
                            {{ theme }}
                        </EntryFilter>
                    </template>
                    <template v-for="region in record.region" :key="region">
                        <EntryFilter href="#" :class="{ 'selected': isSelected(region) }">
                            {{ regionTitle(region) }}
                        </EntryFilter>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    hyphens: auto;
}
</style>

<script>
import { mapGetters } from 'vuex'
import IconExpand from "../components/icons/IconExpand.vue";
import IconCollapse from "../components/icons/IconCollapse.vue";
import IconClose from "../components/icons/IconClose.vue";
import ReadMore from "../components/buttons/ReadMore.vue";
import EntryFilter from "../components/buttons/EntryFilter.vue";

export default {
    name: 'EntryDetail',
    components: {
        IconExpand,
        IconCollapse,
        IconClose,
        ReadMore,
        EntryFilter,
    },
    data() {
        return {
            imageExpanded: false,
        }
    },
    computed: {
        ...mapGetters({
            'regions': 'filters/regions',
            'themes': 'filters/themes',
            'topics': 'filters/topics',
            'search_string': 'timeline/search_string',
            'button_text': 'global/ui_text',
            'is_kiosk': 'global/is_kiosk',

        }),
        buttonText() {
            if (this.button_text.detail) {
                return this.button_text.detail.buttons;
            } else {
                return {}
            }
        },
        siteUrl() {
            return `https://${this.record.domain_name}${this.record.url}`
        },
        year() {
            return this.record.date || '';
        },
        siteName() {
            if (this.record.site_name === 'oep') return 'The Oregon Encyclopedia';
            if (this.record.site_name === 'ohp') return 'The Oregon History Project';
            return '';
        },
        subjects() {
            let subjects = []
            if ('theme' in this.record) {
                let themes = this.record.theme.map(t => this.themeTitle(t));
                subjects = subjects.concat(themes)
            }
            if ('sub_theme' in this.record) {
                let topics = this.record.sub_theme.map(t => this.topicTitle(t));
                subjects = subjects.concat(topics)
            }
            return subjects.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
        },
        displayTitle() {
            return this.record.display_title ? this.record.display_title : '';
        },
        previewText() {
            if (!this.record) return;
            let text = ''
            if (this.record.teaser) {
                text = this.record.teaser.length > 380 ? this.record.teaser.substring(0, 380) + '...' : this.record.teaser;
            }
            return text
        }

    },
    props: {
        record: Object,
    },
    methods: {
        isSelected() {
            return false;
        },
        toggleImageZoom() {
            this.imageExpanded = !this.imageExpanded;
        },
        regionTitle(id) {
            if (!this.regions.length) return ''
            const region = this.regions.find(item => item.key === id)
            return region.title;
        },
        themeTitle(id) {
            if (!this.themes.length) return ''
            const theme = this.themes.find(item => item.key === id)
            return theme.title;
        },
        topicTitle(id) {
            if (!this.topics.length) return ''
            const topic = this.topics.find(item => item.key === id)
            return topic.title;
        },
        formatYear(year) {
            const isNumeric = (n) => {
                return !isNaN(parseFloat(n)) && isFinite(n);
            }
            if (year === 'TI' || year[0] === '-') {
                return '';
            }
            if (year.length > 4 && isNumeric(year.substr(year.length - 4, year.length))) {
                return year.substr(year.length - 4, year.length)
            }
            if (year.length > 4 && isNumeric(year.substr(year.length - 5, year.length - 4))) {
                return year.substr(year.length - 5, year.length - 4)
            } 
            return year
        },
        htmlToText(html) {
            const tempDivElement = document.createElement("div");
            tempDivElement.innerHTML = html;

            return tempDivElement.textContent || tempDivElement.innerText || "";
        },
        navigate(url) {
            this.$router.push(url);
        },
    },
    beforeRouteUpdate(to) {
        let toPath = to.params.slug.split('_')
        let pathId = toPath.pop()

        if (this.record.id !== parseInt(pathId)) {
            this.$store.dispatch('records/getRecord', {
                id: `${to.params.site}-${pathId}`,
            })
        } else {
            if (this.record.site_name !== to.params.site) {
                this.$store.dispatch('records/getRecord', {
                    id: `${to.params.site}-${pathId}`,
                })
            }
        }
    },
    beforeRouteLeave() {
        this.$store.dispatch('records/setSelectedRecord', {})
    },
    mounted() {
        this.$store.dispatch('global/closeAllPanels');

        if (Object.keys(this.record).length == 0) {
            let urlSlug = this.$route.params.slug.split('_');
            let id = urlSlug.pop();

            this.$store.dispatch('records/getRecord', {
                id: `${this.$route.params.site}-${id}`,
            });
        }
    },
    head: {
        title: function () {
            return {
                inner: this.record.title
            }
        },
        meta: function () {
            return [
                { name: 'description', content: this.htmlToText(this.record.teaser), id: 'desc' }
            ]
        },
    },
}
</script>