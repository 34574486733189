export const state = () => ({
    selected_themes: [],
    saved_themes: [],
    selected_topics: [],
    saved_topics: [],
    centerYear: 1800,
    selected_region: null,
    saved_region: null,
    selected_era: null,
    saved_keywords: [],
    keywords: [],
    view_state: {start: 0, end: 100},
    zoom_level: 1,
    zoom_levels: {
        1: {
            level: 1,
            sectionColumns: 20,
            columnWidth: 12,
            imageDimensions: [50, 65],
            yearColumns: 2,
        },
        2: {
            level: 2,
            sectionColumns: 10,
            columnWidth: 24,
            imageDimensions: [75, 90],
            yearColumns: 2,
        },
        3: {
            level: 3,
            sectionColumns: 5,
            columnWidth: 48,
            imageDimensions: [95, 140],
            yearColumns: 5,
        },
        4: {
            level: 4,
            sectionColumns: 2,
            columnWidth: 120,
            imageDimensions: [105, 150],
            yearColumns: 2,
        }
    },
    tl_start: 1680,
    tl_end: 2040,
    tl_years: [],
    tl_sections: [],
    ti_section: {},
    tl_eras: [],
    search_string: '',
    search_obj: {},
    populated_years: [],
    showTI: true,
})

export const actions = {
    async addSelectedTheme({commit, state, dispatch}, data) {
        let themes = [...state.selected_themes, data];
        commit('setSelectedThemes', themes)
        dispatch('getResultsCount')
    },
    async removeSelectedTheme({commit, state, dispatch}, data) {
        let themes = [...state.selected_themes];
        themes.splice(themes.indexOf(data), 1);
        commit('setSelectedThemes', themes)
        dispatch('getResultsCount')
    },
    async clearSelectedThemes({commit, dispatch}) {
        await commit('setSelectedThemes', [])
        dispatch('getResultsCount')
    },
    async addSelectedTopic({commit, state, dispatch}, data) {
        let topics = [...state.selected_topics, data];
        commit('setSelectedTopics', topics)
        dispatch('getResultsCount')
    },
    async removeSelectedTopic({commit, state, dispatch}, data) {
        let topics = [...state.selected_topics];
        topics.splice(topics.indexOf(data), 1);
        commit('setSelectedTopics', topics)
        dispatch('getResultsCount')
    },
    async clearSelectedTopics({commit, dispatch}) {
        await commit('setSelectedTopics', [])
        dispatch('getResultsCount')
    },
    async setRegion({commit, dispatch}, data) {
        await commit('setSelectedRegion', data)
        dispatch('getResultsCount')
    },
    async clearSelectedRegion({commit,dispatch}) {
        await commit('setSelectedRegion', null)
        dispatch('getResultsCount')
    },
    async setEra({commit}, data) {
        commit('setSelectedEra', data)
    },
    async addKeyword({commit, state, dispatch}, data) {
        let words = [...state.keywords, data]
        await commit('setKeywords', words)
        dispatch('getResultsCount')
    },
    async removeKeyword({commit, state, dispatch}, data) {
        let words = [...state.keywords];
        words.splice(words.indexOf(data), 1);
        await commit('setKeywords', words)
        dispatch('getResultsCount')
    },
    async clearKeywords({commit, dispatch}) {
        await commit('setKeywords', [])
        dispatch('getResultsCount')
    },
    async zoomReset({commit}) {
        await commit('setZoomLevel', 1);
        commit('setSections');
    },
    async zoomOut({commit, state}) {
        await commit('setZoomLevel', state.zoom_level - 1);
        commit('setSections');
    },
    async zoomIn({commit, state}) {
        await commit('setZoomLevel', state.zoom_level + 1);
        commit('setSections');
    },
    async zoomSet({commit}, data) {
        await commit('setZoomLevel', data);
        commit('setSections');
    },
    async setYears({commit, state, dispatch, rootState}, data) {
        let yearInterval = state.zoom_levels[state.zoom_level].sectionColumns * 2;
        let yearsData = {};

        // set minimum years to earliest milestones' year

        if (data.length) {
            let yearRange = data.sort((a,b) => a - b);
            let populatedYears = [...new Set(yearRange)]

            // check if years includes "1" for Time Immemorial records
            let initialYear = populatedYears[0]  === 1 ? populatedYears[1] : populatedYears[0]

            let startYear = initialYear - yearInterval;
            startYear = Math.round(startYear / 10) * 10;

            let endYear = parseInt(populatedYears[populatedYears.length - 1]) + yearInterval;
            endYear = Math.round(endYear / 10) * 10;
            if (endYear > 2040) endYear = 2040;
            let minimumYearSpan = (window.innerWidth / 240) * state.zoom_levels[state.zoom_level].sectionColumns;

            let yearSpan = endYear - startYear;
            if (yearSpan < minimumYearSpan) {
                let newBuffer = (minimumYearSpan - yearSpan) / 2;
                startYear = Math.ceil(startYear - newBuffer);
                endYear = Math.ceil(endYear + newBuffer);
            }

            let ctl_start = rootState.global.curated_timeline_span.first_year
            let ctl_end = rootState.global.curated_timeline_span.last_year
            if (startYear > ctl_start) startYear = ctl_start
            if (endYear < ctl_end) endYear = ctl_end
            startYear = Math.ceil(startYear / 10) * 10;

            if (startYear < 1680) startYear = 1680;
            let years = [...Array(endYear).keys()];
            let span = years.slice(startYear);
            yearsData.span = span;
            yearsData.start = startYear;
            yearsData.end = endYear;

            commit('setPopulatedYears', populatedYears);
        } else {
            let years = [...Array(state.tl_end).keys()];
            let span = years.slice(state.tl_start);
            yearsData.span = span;
            yearsData.start = state.tl_start;
            yearsData.end = state.tl_end;
        }

        await commit('setYears', yearsData);
        commit('setSections');
        dispatch('setEras');
    },
    async setEras({commit, state, rootState}) {
        let startYear = state.tl_start;
        let endYear = state.tl_end;

        // make copy of original eras
        let eras = JSON.parse(JSON.stringify(rootState.global.eras)); 

        let inSpan = eras.filter(e => {
            let isDuring = e.start_year >= startYear && e.end_year <= endYear;
            let spanStartsInEra = e.start_year <= startYear && e.end_year >= startYear;
            let spanEndsInEra = e.start_year <= endYear && e.end_year >= endYear;

            return spanStartsInEra || isDuring || spanEndsInEra;
        })

        // set new span for first and last eras
        inSpan.map(e => {
            if (e.start_year < startYear && e.end_year > startYear) {
                e.start_year = startYear;
            }
            if (e.end_year > endYear) {
                e.end_year = endYear;
            }
            return e;
        })

        commit('setEras', inSpan);
    },
    async getResultsCount({state, dispatch}) {
        await dispatch('searchString')
        dispatch('records/count', state.search_obj, {root: true})
    },
    async saveUrlQuery({dispatch, commit}, query) {
        if ('themes' in query) {
            let themes = query['themes'].split(',').map(t => {
                return `theme-${t}`
            })
            commit('setSelectedThemes', themes)
        }
        if ('subthemes' in query) {
            let topics = query['subthemes'].split(',').map(t => {
                return `topic-${t}`
            })
            commit('setSelectedTopics', topics)
        }
        if ('region' in query) {
            commit('setSelectedRegion', parseInt(query['region']))
        }
        if ('q' in query) {
            commit('setKeywords', query['q'].split(','))
        }
        dispatch('searchString')
    },
    async searchString({commit, state}) {
        let urlStr = '';
        let searchObj = {}

        if (state.keywords.length) {
            urlStr += '&q='
            searchObj.query = [];
            state.keywords.forEach((w, i, arr) => {
                urlStr += `${w}${i < arr.length - 1 ? ',' : ''}`;
                searchObj.query.push(w);
            });
        }
        if (state.selected_themes.length) {
            urlStr += '&themes='
            searchObj.theme = [];
            state.selected_themes.forEach((t, i, arr) => {
                let tId = t.split('-')[1];
                urlStr += `${tId}${i < arr.length - 1 ? ',' : ''}`;
                searchObj.theme.push(parseInt(tId));
            });
        }
        if (state.selected_topics.length) {
            searchObj.sub_theme = [];
            urlStr += '&subthemes='
            state.selected_topics.forEach((t, i, arr) => {
                let tId = t.split('-')[1];
                urlStr += `${tId}${i < arr.length - 1 ? ',' : ''}`;
                searchObj.sub_theme.push(parseInt(tId));
            })
        }
        if (state.selected_region) {
            urlStr += `&region=${state.selected_region}`
            searchObj.region = [state.selected_region]
        }

        urlStr = urlStr.replace('&','?')
        commit('setSearchString', urlStr)
        commit('setSearchObj', searchObj)
    },
    async clearAll({dispatch,commit}) {
        dispatch('clearSelectedTopics');
        dispatch('clearSelectedRegion');
        dispatch('clearSelectedThemes');
        dispatch('clearKeywords');
        dispatch('saveSearchObj');
        commit('setSearchString', '')
    },
    async setCenterYr({commit}, data) {
        commit('setCenterYear', data)
    },
    async saveSearchObj({commit, state}) {
        commit('setSavedThemes', state.selected_themes)
        commit('setSavedTopics', state.selected_topics)
        commit('setSavedRegion', state.selected_region)
        commit('setSavedKeywords', state.keywords)
    },
    async showTI({commit}, data) {
        commit('setShowTI', !!data)
    }
}

export const mutations = {
    setSelectedThemes(state, data) {
        state.selected_themes = data;
    },
    setSelectedTopics(state, data) {
        state.selected_topics = data;
    },
    setSelectedRegion(state, data) {
        state.selected_region = data;
    },
    setSelectedEra(state, data) {
        state.selected_era = data;
    },
    setViewState(state, data) {
        state.view_state = data;
    },
    setZoomLevel(state, data) {
        let level = data;
        if (data < 0) {
            level = 0
        } else if (data > 4) {
            level = 4
        }
        state.zoom_level = level;
    },
    setKeywords(state, data) {
        state.keywords = data;
    },
    setYears(state, data) {
        state.tl_start = data.start;
        state.tl_end = data.end;
        state.tl_years = data.span;
    },
    setEras(state, data) {
        state.tl_eras = data
    },
    setSections(state) {
        let yearInterval = state.zoom_levels[state.zoom_level].sectionColumns;
        let timespan = state.tl_years.length;
        let numSections = Math.ceil(timespan / yearInterval);
        
        let sectionId = 1;
        let startYear = state.tl_start;
        let endYear = state.tl_start + yearInterval - 1;
        let sections = [];

        while (numSections > 0) {
            sections.push({
                section: sectionId,
                start_year: startYear,
                end_year: endYear,
            });

            startYear += yearInterval;
            endYear += yearInterval;
            sectionId++;    
            numSections--;
        }

        // add TI section
        let TIsection = {
            section: 0,
            start_year: 0,
            end_year: 0
        }

        state.ti_section = TIsection;
        state.tl_sections = sections;
    },
    setSearchString(state, data) {
        state.search_string = data;
    },
    setSearchObj(state, data) {
        state.search_obj = data;
    },
    setCenterYear(state, data) {
        state.centerYear = data;
    },
    setSavedThemes(state, data) {
        state.saved_themes = data;
    },
    setSavedTopics(state, data) {
        state.saved_topics = data;
    },
    setSavedRegion(state, data) {
        state.saved_region = data;
    },
    setSavedKeywords(state, data) {
        state.saved_keywords = data;
    },
    setPopulatedYears(state, data) {
        state.populated_years = data;
    },
    setShowTI(state, data) {
        state.showTI = data;
    }
}

export const getters = {
    selected_subjects(state) {
        return state.selected_themes.concat(state.selected_topics);
    },
    selected_themes(state) {
        return state.selected_themes;
    },
    saved_themes(state) {
        return state.saved_themes;
    },
    selected_topics(state) {
        return state.selected_topics;
    },
    saved_topics(state) {
        return state.saved_topics;
    },
    selected_region(state) {
        return state.selected_region;
    },
    saved_region(state) {
        return state.saved_region;
    },
    selected_era(state) {
        return state.selected_era;
    },
    view_state(state) {
        return state.view_state;
    },
    zoom_level(state) {
        return state.zoom_levels[state.zoom_level];
    },
    keywords(state) {
        return state.keywords;
    },
    saved_keywords(state) {
        return state.saved_keywords;
    },
    tl_years(state) {
        return state.tl_years;
    },
    tl_sections(state) {
        return state.tl_sections;
    },
    ti_section(state) {
        return state.ti_section;
    },
    tl_eras(state) {
        return state.tl_eras;
    },
    search_string(state) {
        return state.search_string;
    },
    search_obj(state) {
        return state.search_obj;
    },
    centerYear(state) {
        return state.centerYear;
    },
    populatedYears(state) {
        return state.populated_years;
    },
    showTI(state) {
        return state.showTI;
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}