<template>
    <div id="filters" class="overflow-hidden">
        <section class="filter-section relative flyout-item sh-lg:overflow-y-auto">
            <div class="inner pl-[2.5rem] pr-[6.938rem]">
                <div class="wrapper">
                    <h2 class="heading mb-2.5">{{ content.header }}</h2>
                    <Topics :header="content.subjects_header" />
                </div>
                <div class="wrapper">
                    <h2 class="heading mb-2.5">{{ content.refine_header }}</h2>
                    <Regions />
                    <Keyword v-if="!is_kiosk"/>
                </div>
                <div class="filter-wrapper action flex flex-wrap items-center justify-between !border-t-0">
                    <div class="button-wrap flex items-center gap-x-[2.25rem] ml-auto sh-lg:flex-wrap sh-lg:w-[400px]">
                        <span class="total-results results-found sh-lg:mb-[1rem]">{{ help_text }}</span>
                        <button
                                @click="search"
                                :disabled="resultCount == 0"
                                class="close-panel button btn-gold relative"
                                :class="{disabled: resultCount == 0}">
                                <span v-if="needToApply && resultCount > 0" class="absolute -top-[5px] right-[15px]">
                                    <span class="absolute w-[14px] h-[14px] bg-neutral-10 inline-flex justify-center rounded-full animate-ping" aria-hidden="true"></span>
                                    <span class="absolute w-[18px] h-[18px] bg-neutral-10 inline-flex justify-center rounded-full  -top-[2px] -right-[17px]" aria-hidden="true"></span>
                                </span>
                            <span class="text">{{ content.buttons.show }}</span>
                        </button>
                        <a
                           @click="clearFilters"
                           class="text-neutral-20 hover:text-white underline underline-offset-4 decoration-0 hover:cursor-pointer">
                            {{ content.buttons.deselect }}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Topics from "./filters/FilterTopics.vue";
import Regions from "./filters/FilterRegions.vue";
import Keyword from "./filters/FilterKeyword.vue";

export default {
    name: 'FilterPanel',
    components: {
        Topics,
        Regions,
        Keyword,
    },
    watch: {
        resultCount () {
            this.$store.dispatch('global/setApplyFilters', true);
        },
        help_text (newText) {
            this.$announcer.polite(newText)
        }
    },
    computed: {
        ...mapGetters({
            'showFilters': 'global/filter_panel_open',
            'ui_text': 'global/ui_text',
            'resultCount': 'records/prevResults',
            'selected_subjects': 'timeline/selected_subjects',
            'selected_region': 'timeline/selected_region',
            'keywords': 'timeline/keywords',
            'search_obj': 'timeline/search_obj',
            'search_string': 'timeline/search_string',
            needToApply: 'global/need_apply_fiters',
            'is_kiosk': 'global/is_kiosk',
        }),
        content() {
            return this.ui_text.filters;
        },
        help_text() {
            let msg = '';
            if (this.selected_subjects.length || this.selected_region || this.keywords.length) {
                if (this.resultCount > 0) {
                    msg = `${this.resultCount} ${this.content.results}`
                } else {
                    msg = `${this.resultCount} ${this.content.results}. ${this.content.help_text_zero_state}`
                }
            } else {
                msg = this.content.help_text_landing_state
            }

            return msg;
        },
    },
    methods: {
        async search() {
            await this.$store.dispatch('records/search', this.search_obj);
            this.$store.dispatch('global/filterPanelToggle');
            this.$store.dispatch('global/setApplyFilters', false);

            if (this.search_string.length) {
                this.$router.push(`/timeline${this.search_string}`)
            } else {
                let clearObj = {
                    'subthemes': [],
                    'themes': [],
                    'region': [],
                    'q': [],
                }
                this.$router.replace({'query': clearObj});
            }
        },
        clearFilters() {
            this.$store.dispatch('global/setApplyFilters', false);
            this.$store.dispatch('timeline/clearAll');
        }
    }
}
</script>
