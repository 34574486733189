<script>
import { mapGetters } from 'vuex'
import Footer from '../Footer'

export default {
    name: 'MobileLanding',
    components: {
        Footer
    },
    computed: {
        ...mapGetters({
            intro_content: 'global/intro_content',
            loading: 'records/loading',
        })
    },
    methods: {
        viewTimeline() {
            this.$router.push('/timeline')
        }
    },
}
</script>

<template>
    <div id="landing" class="view">
        <div class="inner">
            <div v-if="!loading" class="wrapper scatter-images">
                <div
                     v-for="index in 4"
                     class="image"
                     :class="`image-${index}`"
                     :key="index">
                    <img :src="intro_content.display_images[index].image_url"
                         :alt="intro_content.display_images[index].title" />
                </div>
            </div>
            <div class="wrapper">
                <h1 class="site-title font-clavo text-white text-[1.5rem] leading-[1.4] w-[13.375rem] my-[1.25rem]">
                    {{ intro_content.landing_page_title }}
                </h1>
                <div class="border-b-4 border-b-primary-10 w-[2.563rem] mb-[1.25rem]"></div>
            </div>

            <div class="wrapper flex flex-col flex-wrap md:mb-[2.5rem] md:gap-x-[2rem] md:flex-row md:flex-nowrap">
                <div class="text-wrap mb-[1.875rem] basis-full md:basis-6/12 md:mb-0">
                    <p class="mb-0 text-white">{{ intro_content.landing_page_intro_text }}</p>
                </div>
                <div class="wrapper text-center md:text-left basis-full md:basis-6/12 mb-[3.875rem] md:mb-0">
                    <h2 class="font-clavo text-[1.125rem] font-normal text-primary-10 mb-[1.875rem]">
                        Go to OregonTimeweb.org on a desktop computer to dive into the full experience.
                    </h2>
                    <p class="text-sm leading-[1.2] text-neutral-30 mb-[0.875rem]">
                        Can’t wait? Explore a featured timeline
                    </p>
                    <div class="button-wrap"><a @click="viewTimeline" class="button btn-gold view-timeline">
                            <span class="text">View</span>
                        </a>
                    </div>
                </div>
            </div>

            <Footer class="mobile" />
        </div>
    </div>
</template>