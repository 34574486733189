<script>
import { mapGetters } from "vuex"

export default {
    name: "ZoomMixin",
    computed: {
        ...mapGetters({
            'zoom_level': 'timeline/zoom_level',
            'tl_years': 'timeline/tl_years',
            // 'tl_sections': 'timeline/tl_sections',
        }),
        columnClass () {
            if (!this.zoom_level) return;
            return `zoom-${this.zoom_level.level}`
        },
        isFullyZoomed () {
            return this.zoom_level.level == 4;
        },
        isFullyZoomedOut () {
            return this.zoom_level.level == 1;
        },
        columnWidth () {
            return this.zoom_level.columnWidth
        }
    },
}
</script>