<template>
    <footer id="footer" class="row">
        <div class="wrapper flex justify-between">
            <div class="column mr-5 lg:mr-[2rem]">
                <ul class="logos">
                    <!-- Original Logo -->
                    <!-- <li><a href="https://www.ohs.org/" target="_blank"><OHSIcon/></a></li> -->
                    <!-- 125th logo -->
                    <li><a href="https://www.ohs.org/" target="_blank"><OHS125thIcon/></a></li>
                    <!-- 125th logo -->
                    <li><a href="https://www.neh.gov/" target="_blank"><NEHIcon/></a></li>
                </ul>
            </div>
            <div class="column order-first lg:order-none w-[50%] mr-[2rem] lg:w-full z-[0]">
                <p class="text-neutral-70 mb-[1.875rem] text-xs">This project was made possible in part by an American Rescue Plan Act (ARPA) Grant from the Institute of Museum and Library Services through the Library Services and Technology Act, administered by the State Library of Oregon.</p>
                <p class="text-neutral-70 mb-0 text-xs">© {{ currentYear }} The Oregon Historical Society</p>
            </div>
        </div>
    </footer>
</template>

<script>
    // import OHSIcon from "./icons/IconOHS.vue";
    import OHS125thIcon from './icons/IconOHS125.vue'
    import NEHIcon from "./icons/IconNEH.vue";

    export default {
        name: 'IntroFooter',
        components: {
            // OHSIcon,
            OHS125thIcon,
            NEHIcon,
        },
        computed: {
            currentYear () {
                return new Date().getFullYear();
            }
        }
    }
</script>