<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="14" viewBox="0 0 6 14" fill="none">
        <path d="M0.000466179 1.67031C0.000500189 0.770821 1.25553 0.55589 1.55487 1.40411L6 14H0L0.000466179 1.67031Z" :fill="color"/>
        <path d="M0.000466179 1.67031C0.000500189 0.770821 1.25553 0.55589 1.55487 1.40411L6 14H0L0.000466179 1.67031Z" fill="black" fill-opacity="0.2"/>
    </svg>
</template>
<script>

export default{
    props: {
        color: {
            type: String,
            default: '#3D9E98'
        }
    }
}
</script>
