import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import IntroPanels from '../views/Intro.vue'
import TimelineView from '../views/Timeline.vue'
import EntryDetail from '../views/EntryDetail.vue'


const routes = [
    {
        path: '/',
        name: 'landing-page',
        components: {
            flyout: IntroPanels,
            timeline: TimelineView,
        },
        meta: {
            announcer: {
              message: 'Introduction'
            }
        }
    },
    {
        path: '/timeline/:site/:slug',
        name: 'detail',
        components: {
            timeline: TimelineView,
            detail: EntryDetail
        },
        meta: {
            announcer: {
              message: 'Entry detail panel'
            }
        }
    },
    {
        path: '/timeline',
        name: 'timeline',
        components: {
            timeline: TimelineView
        },
        props: (route) => {
            return {
                params: route.query
            }
        }
    },
]

const router = createRouter({
    history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(),
    routes, 
})


// 2 issues

/*
    x. data doesn't update on back
    2. route is added twice to history


*/

export default router