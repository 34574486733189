<template>
    <ul class="panel-pagination">
        <li :class="page == 1 ? ' active':''">
            <a href="#" @click="this.$emit('goTo', 1)"></a>
        </li>
        <li :class="page == 2 ? ' active':''">
            <a href="#" @click="this.$emit('goTo', 2)"></a>
        </li>
        <li :class="page == 3 ? ' active last':'last'">
            <a href="#" @click="this.$emit('goTo', 3)"></a>
        </li>
    </ul>
</template>

<script>
export default {
    name: "PanelPagination",
    props: {
        page: {
          type: Number,
          required: true,
        },
    }
}
</script>