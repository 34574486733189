<template>
    <section :aria-hidden="hideAria" aria-label="results" ref="section" class="w-[240px] h-full year-section">
        <h3 id="year-title" class="sr-only">{{ span }} to {{ span_end }}</h3>
        <div v-if="showLists">
            <TransitionGroup
                appear 
                name="fade" 
                tag="ul"
                class="timeline-grid h-full"
                :aria-label="`${results.length} results`" 
                :class="[columnClass, recordStyle, {'bg-shades-30/20': showBgColor}]"
                :style="tiRowStyle">

                <li v-for="(group, i) in groups" :key="`${span}-group${i}`"
                    ref="group" 
                    :id="`${span}-group${i}`"
                    class="image-group-wrap" 
                    :class="[
                        placeClass(group, i),
                        {open: open},
                    ]">
                    <ImageGroup 
                        :results="group" 
                        :listI="listI(group, i)"
                        :recordStyle="recordStyle"
                        @open="keepOpen"
                        @close="close">
                    </ImageGroup>
                    <span v-if="showCount(group)" 
                        class="count"
                        :class="countStyle(group)">
                        {{ group.length }}
                    </span>
                </li>
            </TransitionGroup>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ZoomMixin from '../../mixins/ZoomMixin'
import ImageGroup from './ImageGroup'

export default {
    name: 'YearSections',
    mixins: [ZoomMixin],
    components: {
        ImageGroup,
    },
    props: {
        results: {
            type: Array,
            default () {return []},
        },
        recordStyle: {
            type: String,
            default: 'result'
        },
        span: {
            type: Number,
            default: 2000
        },
        span_end: {
            type: Number,
            default: 2000
        },
        sectionI: {
            type: Number,
            default: 0
        },
        hasLast: Boolean,
        isTI: Boolean,
        colorBg: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            open: false,
            rowHeight: '15',
            showLists: false,
        }
    },
    watch: {
        milestonesOpen (newVal) {
            this.setRowHeight(newVal)
        }
    },
    computed: {
        ...mapGetters({
            milestonesOpen: 'global/milestones_open',
        }),
        tiRowStyle() {
            return `grid-template-rows: repeat(30, 20px)`;
        },
        groups () {
            if (!this.results) return [];
            if (this.results.length == 0) return [];

            let sorted = [...this.results];
            let groups = []

            if (this.isTI && !this.isFullyZoomed) {
                sorted.forEach(i => {
                    groups.push([i])
                })
            } else if (this.isFullyZoomed) {
                if (sorted.length > 18) {
                    let half = sorted.length / 2;
                    let group = sorted.splice(0, half);
                    groups.push(group)
                    groups.push(sorted)
                } else if (sorted.length <= 18) {
                    sorted.forEach(r => {
                        groups.push([r])
                    })
                } else {
                    groups.push(sorted)
                }
            } else {
                let maxNum = 16;
                let minNum = 1;
                if (sorted.length < 15 ) {
                    maxNum = 5;
                } else if (sorted.length < 50) {
                    maxNum = 10;
                } else if (sorted.length <= 200) {
                    maxNum = 40;
                } else if (sorted.length > 200) {
                    maxNum = 65;
                }

                if (this.recordStyle == 'curated') {

                    if (sorted.length >= 3) {
                        groups.push(sorted)
                        if (this.hasLast) {
                            let last = sorted.pop()
                            groups.push([last])
                        }
                    } else {
                        sorted.forEach(i => {
                            groups.push([i])
                        })
                    }
                } else {
                    while (sorted.length > maxNum) {
                        let randomNum = Math.floor(Math.random() * (maxNum - minNum) + minNum);
                        let group = sorted.splice(0, randomNum);
                        groups.push(group)
                    }
    
                    if (groups.length < 11) {
                        groups.forEach(g => {
                            if (g.length > 2) {
                                let singleItem = g.pop();
                                groups.push([singleItem]);
                            }
                        })
                    }

                    if (sorted.length <= maxNum) {
                        groups.push(sorted)
                    }
                }


            }
            return groups;
        },
        showBgColor () {
            if (this.recordStyle == 'curated') {
                if (this.results) {
                    if (this.results.length) return true;
                }
                if (this.colorBg) return true;
            } 
            return false;
        },
        hideAria () {
            if (this.recordStyle == 'curated' && !this.milestonesOpen) return true;
            return this.results.length == 0
        }

    },
    methods: {
        close () {
            this.open = false;
        },
        keepOpen () {
            this.open = true;
        },
        placeClass (group, i) {
            let zl = this.zoom_level.level;
            let col = ''
            let row = ''

            // set row class
            
            if (this.recordStyle == 'curated' || zl == 4) {
                row = 'row-start-1';
            } else {
                let random = Math.floor((Math.random() * 64) + 2);
                row = this.results < 10 ?  '' : `row-start-${random}`;
            }

            // set col class
            let placementMap = {
                1: {
                    0: 'col-start-1',
                    1: 'col-start-2',
                    2: 'col-start-3',
                    3: 'col-start-4',
                    4: 'col-start-5',
                    5: 'col-start-6',
                    6: 'col-start-7',
                    7: 'col-start-8',
                    8: 'col-start-9',
                    9: 'col-start-10',
                    10: 'col-start-11',
                    11: 'col-start-12',
                    12: 'col-start-13',
                    13: 'col-start-14',
                    14: 'col-start-15',
                    15: 'col-start-16',
                    16: 'col-start-17',
                    17: 'col-start-18',
                    18: 'col-start-19',
                    19: 'col-start-20',
                },
                2: {
                    0: 'col-start-1',
                    1: 'col-start-2',
                    2: 'col-start-3',
                    3: 'col-start-4',
                    4: 'col-start-5',
                    5: 'col-start-6',
                    6: 'col-start-7',
                    7: 'col-start-8',
                    8: 'col-start-9',
                    9: 'col-start-10',
                },
                3: {
                    0: 'col-start-1',
                    1: 'col-start-2',
                    2: 'col-start-3',
                    3: 'col-start-4',
                    4: 'col-start-5',
                    5: 'col-start-1',
                    6: 'col-start-2',
                    7: 'col-start-3',
                    8: 'col-start-4',
                    9: 'col-start-5',
                },
                4: {
                    0: 'col-start-1',
                    1: 'col-start-2',
                    2: 'col-start-1',
                    3: 'col-start-2',
                    4: 'col-start-1',
                    5: 'col-start-2',
                    6: 'col-start-1',
                    7: 'col-start-2',
                    8: 'col-start-1',
                    9: 'col-start-2',
                },
            }

            if (this.isTI) { 

                row = `row-start-${i}`
                col = 'col-start-1'

                if (zl == 1) {
                    col = i % 2 == 0 ? 'col-start-1' : 'col-start-15';
                    if (i % 3 == 0) col = 'col-start-8';
                } else if (zl == 2) {
                    col = i % 2 == 0 ? 'col-start-1' : 'col-start-5';
                    if (i % 3 == 0) col = 'col-start-10';
                }
                if (zl == 3) {
                    col = i % 2 == 0 ? 'col-start-1' : 'col-start-5';
                    if (i % 3 == 0) col = 'col-start-3';
                } else if (zl == 4) {
                    col = i % 2 == 0 ? 'col-start-1' : 'col-start-2';
                    if (i % 3 == 0) col = 'col-start-1';
                    row = `row-start-1`
                }
            } else {
                let year = 2000;

                if (this.recordStyle == 'curated') {
                    year = group[0].year.toString().match(/(?:[0-9]{4})/g)
                } else {
                    year = group[0].date.toString().match(/(?:[0-9]{4})/g)
                }
                let endDigit = year[0][3];

                if (zl == 1) {
                    let startYr = this.span.toString();
                    if (year[0][2] > startYr[2] ||
                        (year[0][2] == '0' && startYr[2] == '9')) {
                            endDigit = `1${year[0][3]}`;
                    }
                    col = placementMap[zl][endDigit];
                } else if (zl == 4 && this.results.length > 18) {
                    col = `col-start-auto`
                } else {
                    col = placementMap[zl][endDigit];
                }
            }

            return `${col} ${row}`;
        },
        fadeOnLoad() {
            let rect = this.$refs.section.getBoundingClientRect()
            if (rect.left >= 0 && rect.right <= window.innerWidth) {
                this.showLists = true;
            }
        },
        showCount (group) {
            let max = 5;
            if (this.recordStyle === 'curated') max = 3;

            return group.length >= max && this.zoom_level.level != 4;
        },
        countStyle (group) {
            if (this.recordStyle == 'curated') {
                if (group[0].listOrder % 2 !== 0) {
                    return 'bottom'
                }
            }
        },
        setRowHeight(milestonesOpen = false) {
            let wHeight = milestonesOpen ? window.innerHeight - 425 : window.innerHeight - 250;
            let y = Math.floor(wHeight / 70);
            let height = Math.max(5, y);
            this.rowHeight = height;
        },
        listI (group, i) {
            let zl = this.zoom_level.level;
            let col = this.placeClass(group, i);

            if (zl == 4 && col.includes('col-start-2')) {
                return this.groups.length - i;
            } else {
                return i;
            }
        },
    },
    mounted () {
        this.setRowHeight(this.milestonesOpen)

        this.observer = new IntersectionObserver( ([entry]) => {
            if (entry && entry.isIntersecting) {
                this.showLists = true;
            } else {
                this.showLists = false;
            }
        }, {
            threshold: [0],
            rootMargin: '0px 15% 0px 15%'
        });
        if (this.recordStyle == 'result') {
            this.observer.observe(this.$refs.section)  
        } else {
            this.showLists = true;
        }
        this.fadeOnLoad()
    },
    updated () {
       this.fadeOnLoad()
    },
    beforeUnmount () {
        if (this.observer) this.observer.unobserve(this.$refs.section)
    },
}
</script>