<template>
    <div role="listitem">
        <span v-if="viewOnly" class="view-only" :class="{
                    'region': squareTag,
                    'tag': !squareTag,
                }">
            <span class="text"><slot></slot></span>
        </span>
        <button role="button" 
                :aria-pressed="selected"
                v-else @keyup.space="this.$emit('clickAction')" 
                :class="{
                    'selected': selected,
                    'region': squareTag,
                    'tag': !squareTag,
                    'disabled': disabled,
                }">
            <span class="text" @click="this.$emit('clickAction')"><slot></slot></span>
            <span v-if="selected && !squareTag" class="close" @click="this.$emit('xAction')">
                <Close />
                <span class="sr-only">Deselect</span>
            </span>
        </button>
    </div>
</template>

<script>
    import Close from "../icons/IconCloseDark.vue";

    export default {
        name: "TagButton",
        components: {
            Close,
        },
        props: {
            viewOnly: {
                type: Boolean,
                default: false,
            },
            selected: {
                type: Boolean,
                default: false,
            },
            squareTag: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        }
    }
</script>