<template>
    <section class="timeline-sections h-full">
        <template v-if="hasTiResults">
            <!-- NOTE: if we want to spread out the before history section even more,
                 we can create another YearSection and split the entries between them  -->
            <YearSection 
                :key="ti_section.start_year"
                :span="ti_section.start_year"
                :span_end="ti_section.end_year"
                :isTI="true"/>
            <YearSection 
                :key="ti_section.start_year"
                :span="ti_section.start_year"
                :span_end="ti_section.end_year"
                :isTI="true"
                :results="TiResults"/>
            <YearSection 
                :key="ti_section.start_year"
                :span="ti_section.start_year"
                :span_end="ti_section.end_year"
                :isTI="true"/>
        </template>
        <YearSection 
            v-for="(span) in sections" 
            :key="span.start_year"
            :span="span.start_year"
            :span_end="span.end_year"
            :results="spanResults(span)"/>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import YearSection from "./YearSection.vue";


export default {
    name: 'EntriesList',
    components: {
        YearSection,
    },
    data () {
        return {
            spans: [],
        }   
    },
    computed: {
        ...mapGetters({
            'results': 'records/results',
            'sections': 'timeline/tl_sections',
            'ti_section': 'timeline/ti_section',
        }),
        hasTiResults () {
            return this.results.find(r => r.date.includes('TI'))
        },
        TiResults () {
            return this.results.filter(r => r.date.includes('TI'))
        },
    },
    methods: {
        spanResults(span) {
            let spanResults = this.spans.find(s => s.start_year == span.start_year);
            return spanResults ? spanResults.results : [];
        }
    },
    watch: {
        sections () {
            if (!this.sections) return;
            let spans = []

            this.sections.forEach(span => {
                let spanRecords = this.results ? this.results.filter(r => {
                    let year = r.date.match(/(?:[0-9]{4})/g)
                    return year >= span.start_year && year <= span.end_year;
                }) : [];

                spanRecords.sort((a, b) => {
                    let aYear = a.date.match(/(?:[0-9]{4})/g);
                    let bYear = b.date.match(/(?:[0-9]{4})/g);
                    return aYear - bYear
                });

                spans.push({
                    start_year: span.start_year,
                    results: [...spanRecords]
                })
            })

            this.spans = spans;
        }
    }

}
</script>