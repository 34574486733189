import { connection } from "./jsstore_con";

const getDatabase = () => {
    const entries = {
        name: 'Entries',
        columns: {
            db_id: {
                primaryKey: true,
                dataType: 'string',
            },
            id: {
                dataType: 'number',
                notNull: true
            },
            site_name: {
                dataType: 'string',
                notNull: true
            },
            domain_name: {
                dataType: 'string',
                notNull: true
            },
            url: {
                dataType: 'string',
                notNull: true
            },
            image_url: {
                dataType: 'string',
                notNull: true
            },
            thumbnail_url: {
                dataType: 'string',
                notNull: true
            },
            thumbnail_medium_url: {
                dataType: 'string',
                notNull: true
            },
            slug: {
                dataType: 'string',
                notNull: true
            },
            title: {
                dataType: 'string',
                notNull: true
            },
            date: {
                dataType: 'string',
                notNull: true
            },
            body_as_text: {
                dataType: 'string',
                notNull: false
            },
            teaser: {
                dataType: 'string',
            },
            era: {
                dataType: 'array',
                multiEntry: true,
            },
            theme: {
                dataType: 'array',
                multiEntry: true,
            },
            sub_theme: {
                dataType: 'array',
                multiEntry: true,
            },
            region: {
                dataType: 'array',
                multiEntry: true,
            },
        }
    };
    
    const themes = {
        name: 'Themes',
        columns: {
            id: {
                dataType: 'number',
                notNull: true
            },
            key: {
                primaryKey: true,
                dataType: 'number',
                notNull: true
            },
            title: {
                dataType: 'string',
                notNull: true
            },
            slug: {
                dataType: 'string',
                notNull: true
            },
        }
    };

    const topics = {
        name: 'Topics',
        columns: {
            id: {
                dataType: 'number',
                notNull: true
            },
            key: {
                primaryKey: true,
                dataType: 'number',
                notNull: true
            },
            title: {
                dataType: 'string',
                notNull: true
            },
            slug: {
                dataType: 'string',
                notNull: true
            },
        }
    };

    const regions = {
        name: 'Regions',
        columns: {
            id: {
                dataType: 'number',
                notNull: true
            },
            key: {
                primaryKey: true,
                dataType: 'number',
                notNull: true
            },
            title: {
                dataType: 'string',
                notNull: true
            },
            slug: {
                dataType: 'string',
                notNull: true
            },
        }
    };

    const dataBase = {
        name: "OHS_Entries",
        tables: [
            entries,
            themes,
            topics,
            regions,
        ]
    };
    return dataBase;
};

export const initJsStore = async () => {
    const dataBase = getDatabase();
    // eslint-disable-next-line 
    return await connection.initDb(dataBase);
};