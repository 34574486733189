<script>
import { mapGetters } from 'vuex'

export default {
    name: 'AboutPanel',
    computed: {
        ...mapGetters({
            pageContent: 'global/ui_text',
        }),
        aboutContent() {
            if (!this.pageContent.about) return {}
            return this.pageContent.about
        },
    },
}
</script>

<template>
    <div id="about" class="flex justify-end w-[100%]">
        <div class="inner bg-shades-20/90 w-[42.125rem] p-[3.75rem] border-l-2 border-l-secondary-20">
            <h2 class="font-clavo text-2xl font-medium text-white mb-[0.563rem]">{{ aboutContent.header }}</h2>
            <div class="border-b-4 border-b-secondary-20 w-[1.875rem] mb-[2rem]"></div>
            <div class="text text-white">
                <div v-html="aboutContent.body"></div>
                <p class="!text-white/50">{{ aboutContent.credit_line }}</p>
            </div>
        </div>
    </div>
</template>



