import Vuex from 'vuex'

import global from './global'
import timeline from './timeline'
import records from './records'
import filters from './filters'

const store = new Vuex.Store({
    modules: {
        global,
        timeline,
        records,
        filters,
    },
})

export default store