<template>
    <div class="filter-wrapper regions">
        <div class="column">
            <h3 id="region-selection-title" class="label"><span class="text">Region</span></h3>
        </div>
        <div class="column">
            <div class="wrapper options" role="list" aria-labelledby="region-selection-title">
                <Tag v-for="region in regions"
                    :key="region.id"
                    :squareTag="true"
                    :disabled="false"
                    @click-action="toggleRegion(region.key)" 
                    :selected="selectedRegion == region.key">
                    {{ region.title }}
                </Tag>
            </div>
        </div>
    </div>
</template>

<script>
import Tag from "../buttons/Tag.vue";
import { mapGetters } from 'vuex'


export default {
    name: 'FilterRegions',
    components: {
        Tag,
    },
    computed: {
        ...mapGetters({
            selectedRegion: 'timeline/selected_region',
            regions: 'filters/regions'
        }),
    },
    methods: {
        toggleRegion (region) {
            if (region == this.selectedRegion) {
                this.$store.dispatch('timeline/setRegion', null)
            } else {
                this.$store.dispatch('timeline/setRegion', region)
            }
        }
    }
}
</script>
