<template>
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_2409_20313)">
            <circle cx="16.5" cy="14.5" r="12" stroke="white" />
            <path d="M15.842 15.6183L16.696 15.2403C16.458 15.0723 16.332 14.7923 16.332 14.5683C16.332 13.1963 19.034 13.0143 19.034 10.9003C19.034 9.69634 17.998 8.73034 16.29 8.73034C14.96 8.73034 13.924 9.27634 13.252 10.1583L13.952 10.9143C14.47 10.2143 15.128 9.76634 16.192 9.76634C17.284 9.76634 17.83 10.3683 17.83 11.0683C17.83 12.5523 15.31 12.6923 15.31 14.4703C15.31 14.9043 15.464 15.2963 15.842 15.6183ZM16.136 18.3483C16.556 18.3483 16.906 17.9983 16.906 17.5783C16.906 17.1583 16.556 16.8083 16.136 16.8083C15.716 16.8083 15.366 17.1583 15.366 17.5783C15.366 17.9983 15.716 18.3483 16.136 18.3483Z"
                  fill="white" />
        </g>
        <defs>
            <filter id="filter0_d_2409_20313" x="0" y="0" width="33" height="33" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2409_20313" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2409_20313" result="shape" />
            </filter>
        </defs>
    </svg>

</template>