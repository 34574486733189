<template>
    <div id="panel-3" class="active inner mt-[60px] pl-[6.5rem] pr-[6.938rem] !justify-start ">
        <div class="wrapper flex flex-wrap justify-between">
            <div class="column basis-full">
                <h2 class="heading text-[3rem] font-proxima text-secondary-10 font-light leading-none mb-[1.688rem]">
                    {{ data.page_4_title }}</h2>
                <div class="text-wrap">
                    <p class="mb-3.5 text-white">{{ data.page_4_text }}</p>
                </div>
            </div>
        </div>
        <div class="panel-topics-themes mb-[12rem] sh-lg: mb-[9rem] overflow-y-auto">
            <Subject v-for="region in data.region_selection"
                     :key="localId(region.region_api_id)"
                     :isSelected="selected_region === localId(region.region_api_id)"
                     @click="setRegion(localId(region.region_api_id))">
                <span class="text">{{ region.region_name }}</span>
                <img
                     class="subject-image -rotate-[5deg]"
                     :src="region.image_url"
                     :alt="region.region_name" />
            </Subject>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Subject from "../buttons/Subject.vue";

export default {
    name: "PanelThree",
    props: { data: Object },
    components: {
        Subject,
    }, computed: {
        ...mapGetters({ 
            'selected_region': 'timeline/selected_region',
            'regions': 'filters/regions',
        })
    },
    methods: {
        localId(id) {
            let region = this.regions.find(r => r.id == id)
            return region.key;
        },
        setRegion(id) {
            if (this.selected_region === id) {
                this.$store.dispatch('timeline/setRegion', null)
            }
            else {
                this.$store.dispatch('timeline/setRegion', id)
            }
        }
    }
}
</script>