<template>
    <div class="timeline-topics-themes">
        <h2 id="results-title">
            <span class="text">My Timeline</span>
            <span class="count" aria-live="polite"> {{ returnedResults }} / {{ totalResults }} <span
                      class="sr-only">results </span></span>
        </h2>
        <div class="wrapper topics">
            <h3 id="selected-list-title" class="sr-only">Selected filters</h3>
            <ul aria-labelledby="selected-list-title" @click="this.$store.dispatch('global/filterPanelToggle')">
                <template v-for="(selected, i) in selectedItems" :key="selected">
                    <li><span class="underline">{{ selected }}</span><span class="mr-1 inline-block"
                              v-if="i < selectedItems.length - 1">,</span></li>
                </template>
                <li v-if="noFilters">
                    <span class="underline">All Subjects</span>, <span class="underline">All Regions</span>
                </li>
                <li v-if="overflow">, <span class="underline">more</span> > </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ResultTopics',
    computed: {
        ...mapGetters({
            'selectedThemes': 'timeline/saved_themes',
            'selectedTopics': 'timeline/saved_topics',
            'selectedRegion': 'timeline/saved_region',
            'returnedResults': 'records/num_results',
            'totalResults': 'records/total_results',
            'themes': 'filters/themes',
            'topics': 'filters/topics',
            'regions': 'filters/regions',
        }),
        overflow() {
            let selected = this.selectedThemes.length + this.selectedTopics.length;
            let showMore = this.selectedRegion ? selected + 1 : selected;
            return showMore > 4;
        },
        noFilters() {
            return this.selectedThemes.length == 0 &&
                this.selectedTopics.length == 0 &&
                this.selectedRegion == null
        },
        selectedItems() {
            let themes = this.selectedThemes.map(t => this.title(t));
            let topics = this.selectedTopics.map(t => this.title(t));
            let region = this.selectedRegion ? this.regionName(this.selectedRegion) : null;

            let allSelected = [];
            allSelected = allSelected.concat(themes)
            allSelected = allSelected.concat(topics)
            if (this.selectedRegion) allSelected.push(region)
            if (allSelected.length) allSelected.sort()

            return allSelected.length > 4 ? allSelected.slice(0, 4) : allSelected;
        }
    },
    methods: {
        title(id) {
            if (!this.themes.length) return;

            let title = '';
            if (id.includes('theme')) {
                let theme = this.themes.find(t => t.localId == id);
                title = theme ? theme.title : '';
            } else {
                let topic = this.topics.find(t => t.localId == id);
                title = topic ? topic.title : '';
            }
            return title;
        },
        regionName(id) {
            return this.regions.find(r => r.key == id).title;
        }
    }
}
</script>
