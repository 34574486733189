<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="14" viewBox="0 0 6 14" fill="none">
        <path d="M0.000466588 12.3297C0.000500518 13.2292 1.25553 13.4441 1.55487 12.5959L6 5.24537e-07L1.48619e-06 0L0.000466588 12.3297Z" :fill="color"/>
        <path d="M0.000466588 12.3297C0.000500518 13.2292 1.25553 13.4441 1.55487 12.5959L6 5.24537e-07L1.48619e-06 0L0.000466588 12.3297Z" fill="black" fill-opacity="0.2"/>
    </svg>
</template>

<script>
export default{
    props: {
        color: {
            type: String,
            default: '#3D9E98'
        }
    }
}
</script>
