<template>
    <div class="subject" :class="isSelected ? 'selected' : ''">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "SubjectButton",
    props: {
        isSelected: Boolean,
    }
}
</script>