import { connection } from "../service/jsstore_con.js";

export const state = () => ({
    results: [],
    detail: {},
    loading: true,
    error: null,
    totalResults: 0,
    returnedResults: 0,
    searches: [],
    searchType: null,
    prevResults: 0,
    mobileRecords:[],
})

export const actions = {
    async initResults({commit, rootState}) {

        if (rootState.global.cache) {
            await connection.select({
                from: 'Entries',
            }).then(results => {
                commit('setResults', results)
            })

            await connection.count({
                from: "Entries",
            }).then(count => {
                commit('setTotalResults', count);
            })
        } else {

            localStorage.setItem('ohstw-last-update', new Date())

            // get data from api and populate database
            let searchString = `/api/articles/search/timeweb/?i=*&format=json&page_size=4000`;
            const response = await fetch(`${process.env.VUE_APP_OE_API_URL}${searchString}`);
            let results = await response.json();
            let withDates = results['results']
                                .filter(r => {
                                    let keep = true;
                                    if (r.site_name == 'ohp') {
                                        keep = r.classification === 6;
                                    } else {
                                        keep = r.type == 0;
                                    }
                                    let dates = r.date.match(/(?:[0-9]{4})/g);
                                    if (dates) keep = dates[0] > 1680;

                                    return keep;
                                }).map(r => {
                                    r.db_id = `${r.site_name}-${r.id}`
                                    r.body_as_text = ''  // prevent old db schema from throwing error 
                                    return r;
                                });

            await connection.clear('Entries');
            await connection.insert({
                into: 'Entries',
                upsert: true,
                return: true,
                values: withDates
            }).then(results => {
                commit('setResults', results)
                commit('setTotalResults', results.length);
                commit('setNumResults', results.length);
            });
        }
    },
    async createSearch({commit}, query = {}) {

        let searches = [];
        let searchType = 'union';

        if (Object.keys(query).length) {

            let expandFilters = ['theme', 'sub_theme'];
            if ('region' in query || 'query' in query) {
                // console.log('has region in query')

                if ('region' in query) {
                    searches.push({
                        from: 'Entries',
                        where: {
                            region: { 
                                in: query['region'] 
                            }
                        }
                    });
                }
                
                if ('query' in query) {
                    // console.log('has keywords')

                    query['query'].forEach(q => {
                        searches.push({
                            from: 'Entries',
                            where: {
                                title: { 
                                    like: `%${q}%`,
                                }, 
                                or: {
                                    teaser: {
                                        like: `%${q}%`,
                                    }
                                },
                            }
                        });
                    })    
                }
                
                if ('theme' in query && 'sub_theme' in query) {
                    // console.log('has theme and subtheme')

                    searches.push({
                        from: 'Entries',
                        where: {
                            theme: {
                                in: query['theme']
                            },
                            or: {
                                sub_theme: {
                                    in: query['sub_theme']
                                }
                            }
                        }
                    })
                } else if ('theme' in query || 'sub_theme' in query) {
                    // console.log('has only theme OR subtheme + query')

                    expandFilters.forEach(filter => {
                        if (filter in query) {
        
                            let search = {
                                from: 'Entries'
                            };
                            let where = {};
                            
                            where[filter] = {
                                in: query[filter]
                            }
                            
                            search.where = where;
                            searches.push(search)
                        }
                    })               
                }

                if (Object.keys(query).length > 1) {
                    searchType = 'intersect';
                }

            } else {
                // console.log('has theme and/or subtheme, but no region or query')

                expandFilters.forEach(filter => {
                    if (filter in query) {
    
                        let search = {
                            from: 'Entries'
                        };
                        let where = {};
                        
                        where[filter] = {
                            in: query[filter]
                        }
                        
                        search.where = where;
                        searches.push(search)
                    }
                })
            }
        } else {
            searches.push({
                from: 'Entries'
            })
        }

        await commit('setSearchType', searchType)
        await commit('setSearches', searches)
    },
    async search({dispatch, commit, state}, query = {}) {
        await dispatch('createSearch', query)

        if (state.searchType == 'union') {
            await connection.union(state.searches).then(results => {
                // console.log('union results')
                // console.log(results)
                commit('setResults', results)
                commit('setNumResults', results.length);
                commit('setPreviewNumResults', results.length);
                dispatch('updateTLspan', results)
                dispatch(
                    'timeline/showTI', 
                    results.find(r => r.date.includes('TI')),
                    {root: true}
                )
                commit('setLoading', false)
            })
        } else {
            await connection.intersect({queries: state.searches}).then(results => {
                // console.log('intersect results')
                // console.log(results)
                commit('setResults', results)
                commit('setNumResults', results.length);
                commit('setPreviewNumResults', results.length);
                dispatch('updateTLspan', results)
                dispatch(
                    'timeline/showTI', 
                    results.find(r => r.date.includes('TI')),
                    {root: true}
                )
                commit('setLoading', false)
            })
        }
        if (Object.keys(query).length) {
            dispatch('timeline/saveSearchObj', {}, {root: true})
        }
    },
    async count({dispatch, commit, state}, query) {
        await dispatch('createSearch', query)
        if (state.searchType == 'union') {
            await connection.union(state.searches).then(results => {
                commit('setPreviewNumResults', results.length)
            })
        } else {
            await connection.intersect({queries: state.searches}).then(results => {
                commit('setPreviewNumResults', results.length)
            })
        }
    },
    async setSelectedRecord({commit}, data) {
        commit('setSelectedRecord', data)
    },
    async getRecord({commit}, data) {
        let id = data.id;
    
        let search = {
            from: 'Entries',
            where: {
                db_id: id
            }
        };

        connection.select(search).then(results => {
            if (results.length) {
                commit('setSelectedRecord', results[0])
            } else {
                console.error('Could not find entry', data.id)
            }
        })
    },
    async getMobileRecords(
        {commit}
    ) {
        const slugs = ['petitions-to-congress-1838-1843', 'young-ewing', 'provisional_govt_conference_in_champoeg_1843', 'oregon_donation_land_act', ];

        const queries = slugs.map(slug => {
            return {
                from: 'Entries',
                where: {
                    slug: slug,
                }
            }
        })

        await connection.union(queries).then(result => {
            commit('setMobileRecords', result)
            // console.log(result)
        })
    },
    async updateTLspan({dispatch}, data) {
        let span = data.map(r => {
            if (r.date.includes('TI')) {
                return 1;
            } else if (r.date.includes('BP')) {
                return null;
            } else {
                let years = r.date.match(/(?:[0-9]{4})/g)
                return years ? years[0] : null;
            }
        }).filter(y => !!y)

        dispatch('timeline/setYears', span, {root: true})
    },
    toggleLoading ({commit}, value) {
        commit('setLoading', value)
    },
}

export const mutations = {
    setSelectedRecord(state, data) {
        state.detail = data;
    },
    setResults(state, data) {
        state.results = data;
    },
    setNumResults(state, data) {
        state.returnedResults = data;
    },
    setPreviewNumResults(state, data) {
        state.prevResults = data;
    },
    setTotalResults(state, data) {
        state.totalResults = data;
    },
    setLoading(state, data) {
        state.loading = data;
    },
    setError(state, data) {
        state.error = data;
    },
    setSearchType(state, data) {
        state.searchType = data;
    },
    setSearches(state, data) {
        state.searches = data;
    },
    setMobileRecords(state, data){
        state.mobileRecords = data
    }
}

export const getters = {
    results(state) {
        return state.results;
    },
    num_results(state) {
        return state.returnedResults;
    },
    total_results(state) {
        return state.totalResults;
    },
    detail(state) {
        return state.detail;
    },
    loading(state) {
        return state.loading;
    },
    error(state) {
        return state.error;
    },
    searches(state) {
        return state.searches;
    },
    searchType(state) {
        return state.searchType;
    },
    prevResults(state) {
        return state.prevResults;
    },
    mobileRecords(state) {
        return state.mobileRecords;
    }

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}