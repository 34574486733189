<template>
    <ul class="timeline-tools" :class="{'show-tooltips': aboutPanelOpen}">
        <li>
            <button ref="zoomInBtn" @click="zoomIn" :disabled="current_zoom == 4">
                <IconZoomIn />
                <span class="sr-only">{{ buttonText.zoom_in }}</span>
            </button>
            <Transition name="fade">
            <div
                 ref="zoomInTooltip"
                 id="zoomInTooltip"
                 class="tooltip right"
                 :class="aboutPanelOpen ? 'show-tooltips' : 'hide-tooltips'"
                 role="tooltip">
                <div class="tooltip-content">
                    {{ buttonText.zoom_in }}
                </div>
            </div>
            </Transition>
        </li>
        <li>
            <button ref="zoomOutBtn" @click="zoomOut" :disabled="current_zoom == 1">
                <IconZoomOut />
                <span class="sr-only">{{ buttonText.zoom_out }}</span>
            </button>
            <Transition name="fade">
            <div
                 ref="zoomOutTooltip"
                 id="zoomOutTooltip"
                 class="tooltip right"
                 :class="aboutPanelOpen ? 'show-tooltips' : 'hide-tooltips'"
                 role="tooltip">
                <div class="tooltip-content">
                    {{ buttonText.zoom_out }}
                </div>
            </div>
            </Transition>
        </li>
        <li>
            <button ref="shareBtn" @click="toggleShareModal">
                <IconShare />
                <span class="sr-only">{{ buttonText.share }}</span>
            </button>
            <Transition name="fade">
            <div
                 ref="shareTooltip"
                 id="share-tooltip"
                 class="tooltip right"
                 :class="aboutPanelOpen ? 'show-tooltips' : 'hide-tooltips'"
                 role="tooltip">
                <div class="tooltip-content">
                    {{ buttonText.share }}
                </div>
            </div>
            </Transition>
        </li>
    </ul>
</template>

<script>
import { createPopper } from '@popperjs/core'

import { mapGetters } from 'vuex'
import IconZoomIn from "../icons/IconZoomIn.vue";
import IconZoomOut from "../icons/IconZoomOut.vue";
import IconShare from "../icons/IconShare.vue";

export default {
    name: 'ToolMenu',
    components: {
        IconZoomIn,
        IconZoomOut,
        IconShare,
    },
    data() {
        return {
            current_zoom: 1,
        }
    },
    computed: {
        ...mapGetters({
            'zoom_level': 'timeline/zoom_level',
            'share_modal_open': 'global/share_modal_open',
            'aboutPanelOpen': 'global/about_panel_open',
            pageContent: 'global/ui_text',
        }),
        buttonText () {
            if (this.pageContent.timeline) {
                return this.pageContent.timeline.buttons;
            } else {
                return {}
            }
        }
    },
    watch: {
        zoom_level(newZ) {
            if (newZ) {
                this.current_zoom = newZ.level;
            }
        },
    },
    methods: {
        zoomOut() {
            this.$store.dispatch('timeline/zoomOut');
            this.$announcer.polite('Timeline zoomed out to increments of ' + this.zoom_level.sectionColumns)
        },
        zoomIn() {
            this.$store.dispatch('timeline/zoomIn');
            this.$announcer.polite('Timeline zoomed in to increments of ' + this.zoom_level.sectionColumns)
        },
        toggleShareModal() {
            this.$store.dispatch('global/shareModalToggle');
        },
        tooltipPopper(button, tooltip, placement, offset) {
            const popper = createPopper(button, tooltip, {
                placement: placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: offset,
                        },
                    },
                ],
            })

            return () => popper
        },
    },
    mounted () {
        this.tooltipPopper(this.$refs.zoomInBtn, this.$refs.zoomInTooltip, 'left', [-18, 94])
        this.tooltipPopper(this.$refs.zoomOutBtn, this.$refs.zoomOutTooltip, 'left', [-18, 104])
        this.tooltipPopper(this.$refs.shareBtn, this.$refs.shareTooltip, 'left', [-18, 79])

    }
}
</script>