<template>
    <div
         id="panel-0"
         class="inner active !justify-between lg:!justify-center sh-lg:!justify-start pt-[3rem] lg:pt-[1.5rem] lg:-mt-[2rem] sh-lg:mt-0 xl:mt-0 pl-[2.625rem] xl:pl-[6.5rem] pr-[3.125rem] lg:pr-[6.938rem]">
        <div class="wrapper flex flex-nowrap z-[1]">
            <div class="column basis-[100px] mr-[3rem] xl:mr-[5rem] shrink-0 grow-0">
                <h1 class="site-title font-clavo text-white text-[2rem] leading-[1.4] w-[24rem] mb-[1.625rem]">
                    {{ data.landing_page_title }}</h1>
                <div class="border-b-4 border-b-primary-10 w-[40px] mb-[4rem]"></div>
                <div class="text-wrap">
                    <p class="mb-[2rem] text-white">{{ data.landing_page_intro_text }}</p>
                    <div class="button-wrap">
                        <button class="button btn-gold z-[99]">
                            <span class="text"
                                  @click="this.$emit('nextPanel')">{{ buttons.start }}</span>
                        </button>
                        <a
                           class="text-neutral-20 underline underline-offset-4 decoration-0 ml-5 hover:cursor-pointer hover:text-white z-[99]"
                           @click="skipToTimeline">
                            {{ buttons.skip }}
                        </a>
                    </div>
                    
                </div>
            </div>
            <div class="column basis-[30%] flex flex-wrap items-center relative">
                <div ref="images" 
                     class="animate stack-images relative z-[1] w-[29rem] sh-lg:w-[26rem] h-[18rem] self-center">
                    <img src="../../assets/images/Summer-Camp-Columbia-River-1884.png"
                         style="z-index: 3;transform: rotate(-2.71deg);" 
                         alt="Illustration of summer camp columbia river in 1884" />
                    <img src="../../assets/images/From-Jean-Baptiste-Charbonneau-1848.png"
                         style="z-index: 2;transform: rotate(-12.32deg);" 
                         alt="Photo of a letter written by Jean Baptiste Charbonneau from 1884" />
                    <img src="../../assets/images/Whale-Butchering-Coos-Bay-1856.png"
                         style="z-index: 1;transform: rotate(-21.32deg);" 
                         alt="Illustration of a whale butchering in 1856" />
                </div>
                <div>
                    <ul class="flex absolute top-[75px]">
                        <li class="ml-5 text-2xl">1886</li>
                        <li class="ml-5 text-2xl">1888</li>
                        <li class="ml-5 text-2xl">1890</li>
                        <li class="ml-5 text-2xl">1892</li>
                        <li class="ml-5 text-2xl">1894</li>
                        <li class="ml-5 text-2xl">1896</li>
                    </ul>
                </div>
                <div
                     class="selected-topics-themes z-[2] relative bottom-10 lg:bottom-5 xl:bottom-0 sh-lg:bottom-[3rem] lg:left-[10rem] xl:left-[15rem] w-[25.688rem] mr-[0rem] mt-[2rem]">
                    <h2 class="heading mb-0">Summer Fishing Camp on the Columbia</h2>
                    <span class="year pt-1">1884</span>
                    <div class="topics-themes-list wrapper flex flex-wrap gap-2.5 pt-4">
                        <Tag :viewOnly="true">Arts</Tag>
                        <Tag :viewOnly="true">Environment and Natural Resources</Tag>
                        <Tag :viewOnly="true">Oregon Trail and Resettlement</Tag>
                        <Tag :viewOnly="true">Native Americans</Tag>
                        <Tag :viewOnly="true" :squareTag="true">Central Columbia River</Tag>
                    </div>
                </div>
            </div>

        </div>
        <Footer class="desktop" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Footer from "../Footer.vue";
import Tag from "../buttons/Tag.vue";

export default {
    name: 'PanelStart',
    props: { data: Object, buttons: Object },
    components: {
        Footer,
        Tag,
    },
    computed: {
        ...mapGetters({
            'search_string': 'timeline/search_string',
        })
    },
    methods: {
        skipToTimeline() {
            if (this.$route.name != 'timeline') {
                this.$router.push(`/timeline${this.search_string}`)
            }
            this.$store.dispatch('global/closeAllPanels');
            this.$store.dispatch('global/setShowLearnTooltip', true);
            setTimeout(() => {
                this.$store.dispatch('global/setShowLearnTooltip', false);
            }, 4500)
        },
    }, 
    mounted () {
        if (this.$refs.images) {
            setTimeout(() => {
                this.$refs.images.classList.remove('animate');
            }, 10)
        }
    }
}
</script>