<template>
    <div>
        <span v-if="stacked" :style="style" class="entry-image cursor-pointer">
            <img ref="entry" :alt="record.title" src="https://www.oregonencyclopedia.org/static/img/default-thumbnail.png" :data-src="`https://${record.domain_name}${record.thumbnail_url}`" width="90" height="100" />
        </span>
        <router-link v-else :to="entryUrl" :style="style" class="entry-image" role="button" @keyup.space="navigate(entryUrl)">
            <img ref="entry" :alt="record.title" src="https://www.oregonencyclopedia.org/static/img/default-thumbnail.png" :data-src="`https://${record.domain_name}${record.thumbnail_url}`" width="90" height="100" />
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'EntryImage',
    props: {
        href: {
          type: String,
          required: true,
        },
        record: {
            type: Object,
            required: true,
        },
        stacked: {
            type: Boolean,
        },
        style: String
    },
    computed: {
        ...mapGetters({
            'search_string': 'timeline/search_string',
            'search_obj': 'timeline/search_obj'
        }),
        slug () {
            return this.record.slug.split('/').reverse()[0] 
        },
        entryUrl () {
            return `/timeline/${ this.record.site_name }/${ this.slug }_${ this.record.id }${this.search_string}`
        }, 
     },
     methods: {
        navigate (url) {
            this.$router.push(url);
        }
     },
    mounted () {
        this.observer = new IntersectionObserver( ([entry]) => {
            if (entry && entry.isIntersecting) {
                entry.target.src = entry.target.dataset.src;
                this.observer.unobserve(this.$refs.entry)
            }
        }, {
            threshold: [0],
            rootMargin: '0px 200px 0px 200px'
        });

        this.observer.observe(this.$refs.entry)
    },
    beforeUnmount () {
        if (this.observer) this.observer.unobserve(this.$refs.entry)
    },
}
</script>