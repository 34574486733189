<template>
    <section id="milestone-section" class="milestone-sections" aria-labelledby="curated-title"> 
        <h2 :aria-hidden="!milestonesOpen" if="curated-title" class="sr-only">Curated timeline entries</h2>
        <template v-if="showTI">
            <YearSection 
                v-if="showTI" 
                :key="ti_section.start_year"
                :span="ti_section.start_year"
                :span_end="ti_section.end_year"
                :isTI="true"
                recordStyle="curated"/>
            <YearSection 
                v-if="showTI" 
                :key="ti_section.start_year"
                :span="ti_section.start_year"
                :span_end="ti_section.end_year"
                :isTI="true"
                recordStyle="curated"
                :colorBg="TiResults.length > 0"
                :results="TiResults"/>
            <YearSection 
                v-if="showTI" 
                :key="ti_section.start_year"
                :span="ti_section.start_year"
                :span_end="ti_section.end_year"
                :colorBg="TiResults.length > 0"
                :isTI="true"
                recordStyle="curated"/>
        </template>

        <YearSection 
            v-for="(span, i) in sections" 
            :key="span.start_year"
            :sectionI="i"
            :span="span.end_year"
            :span_end="span.end_year"
            :results="spanMilestones(span)"
            :hasLast="hasLast(span)"
            :hasFirst="false"
            :colorBg="inSpan(span)"
            recordStyle="curated"/>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import YearSection from "./YearSection.vue";


export default {
    name: 'MilestoneTimeline',
    components: {
        YearSection,
    },
    data () {
        return {
            spans: [],
        }
    },
    computed: {
        ...mapGetters({
            'curated_timeline': 'global/selected_curated_timeline',
            'sections': 'timeline/tl_sections',
            'showTI': 'timeline/showTI',
            'results': 'records/results',
            'ti_section': 'timeline/ti_section',
            milestonesOpen: 'global/milestones_open',
            centerYear: 'timeline/centerYear',

        }),
        milestones () {
            if (!this.curated_timeline) return [];
            let items = this.curated_timeline.timeline_records || []

            return items.sort((a, b) => {
                    return a.year - b.year
                }).map((r,i) => {
                    r.listOrder = i + 1;
                    return r;
                });
        },
        lastMilestoneYear () {
            if (!this.milestones.length) return 0
            return parseInt(this.milestones[this.milestones.length - 1].year)
        },
        firstMilestoneYear () {
            if (!this.milestones.length) return 0
            let firstYear = this.milestones.find(m => m.year).year
            return firstYear == 'TI' || firstYear.includes('before 1680') ? 0 : parseInt(firstYear)
        },
        showOld () {
            return false;
        },
        TiResults () {
            if (!this.curated_timeline.timeline_records) return [];

            return this.curated_timeline.timeline_records.filter(r => {
                let isTi = false;
                if (r.year) {                    
                    isTi = r.year.includes('TI') || r.year.includes('before 1680')
                }
                return isTi;
            })
        },
    },
    methods: {
        spanMilestones (span) {
            let spanResults = this.spans.find(s => s.start_year == span.start_year);
            return spanResults ? spanResults.results : [];
        },
        hasLast (span) {
            return span.start_year <= this.lastMilestoneYear && span.end_year >= this.lastMilestoneYear;
        },
        setSpanSections() {
            let spans = []

            this.sections.forEach(span => {
                let spanRecords = this.milestones ? this.milestones.filter(r => {
                    return r.year >= span.start_year && r.year <= span.end_year;
                }) : [];
            
                spanRecords.sort((a, b) => {
                    let aYear = a.year.match(/(?:[0-9]{4})/g);
                    let bYear = b.year.match(/(?:[0-9]{4})/g);
                    return aYear - bYear
                });

                spans.push({
                    start_year: span.start_year,
                    results: [...spanRecords]
                })
            })
    
            this.spans = spans;
        },
        moveToFirstEntry() {
            if (this.centerYear > this.firstMilestoneYear &&
                this.centerYear < this.lastMilestoneYear) return;

            let c = document.querySelector(`#yr-${this.firstMilestoneYear}`)
            if (c) {
                let newCenter = c.offsetLeft - window.innerWidth/3;
                this.$emit('scrollToCenter', newCenter, 'smooth')
                this.$announcer.polite('Curated timeline panel opened and timeline scrolled to ' + this.firstMilestoneYear)
            }
        },
        inSpan(span) {
            if (span.start_year >= this.firstMilestoneYear &&
                span.end_year <= this.lastMilestoneYear) {
                return true;
            }
        }
    },
    watch: {
        curated_timeline (newVal, oldVal) {
            this.setSpanSections()

            if (Object.keys(oldVal).length > 0) {
                this.moveToFirstEntry()
                this.$announcer.polite('Curated timeline changed to' + newVal.title)
            }            
        },
        sections () {
            this.setSpanSections()
        },
        milestonesOpen (newVal, oldVal) {
            if (newVal) {
                this.moveToFirstEntry()
            } else if (oldVal) {
                this.$announcer.polite('Curated timeline panel closed')
            }
        },
    }
}
</script>