import { connection } from "../service/jsstore_con.js";

export const state = () => ({
    themes: [],
    topics: [],
    regions: [],
    subjects: [],
})

export const actions = {
    async fetchThemes({commit, rootState}) {
        if (rootState.global.cache) {
            // get themes from cache if available
            await connection.select({
                from: 'Themes',
            }).then(data => {
                commit('setThemes', data)
            })
        } else {
            const oeResponse = await fetch(`${process.env.VUE_APP_OE_API_URL}/metadata/theme/?api_token=${process.env.VUE_APP_API_TOKEN}`);
            const data = await oeResponse.json();

            const ohpResponse = await fetch(`${process.env.VUE_APP_OHP_API_URL}/metadata/theme/?api_token=${process.env.VUE_APP_OHP_TOKEN}`);
            const ohpData = await ohpResponse.json();

            ohpData.forEach(item =>{
                if(!data.find(t => t.key == item.key)){
                    data.push(item);
                }
            })
            
            await connection.insert({
                into: 'Themes',
                values: data,
                upsert: true,
                return: true
            }).then(data => {
                commit('setThemes', data)
            })
        }
    },
    async fetchTopics({commit, rootState}) {
        if (rootState.global.cache) {
            // get topics from cache if available
            await connection.select({
                from: 'Topics',
            }).then(data => {
                commit('setTopics', data)
            })
        } else {
            const response = await fetch(`${process.env.VUE_APP_OE_API_URL}/metadata/subtheme/?api_token=${process.env.VUE_APP_API_TOKEN}`);
            const data = await response.json();
            await connection.insert({
                into: 'Topics',
                values: data,
                upsert: true,
                return: true
            }).then(data => {
                commit('setTopics', data)
            })
        }
    },
    async fetchSubjects({commit, dispatch}) {
        await dispatch('fetchTopics')
        await dispatch('fetchThemes')
        commit('setSubjects')
    },
    async fetchRegions({commit, rootState}) {
        if (rootState.global.cache) {
            // get regions from cache if available
            await connection.select({
                from: 'Regions',
            }).then(data => {
                commit('setRegions', data)
            })
        } else {
            const response = await fetch(`${process.env.VUE_APP_OE_API_URL}/metadata/region/?api_token=${process.env.VUE_APP_API_TOKEN}`);
            const data = await response.json();
            await connection.insert({
                into: 'Regions',
                values: data,
                upsert: true,
                return: true
            }).then(data => {
                commit('setRegions', data)
            })
        }
    },
}

export const mutations = {
    setThemes(state, data) {
        state.themes = data;
    },
    setTopics(state, data) {
        state.topics = data;
    },
    setSubjects(state) {
        let topics = state.topics.map(t => {
            t.type = 'topic'
            t.localId = 'topic-' + t.key;
            return t;
        });
        let themes = state.themes.map(t => {
            t.type = 'theme'
            t.localId = 'theme-' + t.key;
            return t;
        });

        let subjects = topics.concat(themes);
        state.subjects = subjects.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
    },
    setRegions(state, data) {
        state.regions = data;
    },
}

export const getters = {
    themes(state) {
        return state.themes;
    },
    topics(state) {
        return state.topics;
    },
    regions(state) {
        return state.regions;
    },
    subjects(state) {
        return state.subjects;
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}