
<template>
    <div class="spinner-container text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
        <ul id="text-spinner">
            <li class="animate" ref="eras" v-for="(e) in eras" :key="e">{{ e }}</li>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'SpinnerText',
    data () {
        return {
            eras: [
                'Oregon Country before 1792',
                'Early Exploration, Fur Trade, Missionaries, and Settlement',
                'Treaties, Civil War, and Immigration',
                'Industrialization and Progressive Reform',
                'Great Depression and World War II',
                'New Economy, Civil Rights, and Environmentalism',
                'Recent Oregon History',
            ],
        }
    },
}
</script>

<style scoped>
.spinner-container {
    height: 5rem;
    width: 100%;
    overflow: hidden;
}

#text-spinner {
    color: white;
    font-size: 3rem;
}

#text-spinner li {
    width: 100%;
    text-align: center;
    position: absolute;
    opacity: 0;
    
}

#text-spinner li.animate {
    animation: cycle;
    animation-duration: 14s;
    animation-iteration-count: infinite;
    animation-fill-mode:  forwards;
}

#text-spinner li:nth-child(2) { animation-delay: 2s}
#text-spinner li:nth-child(3) { animation-delay: 4s}
#text-spinner li:nth-child(4) { animation-delay: 6s}
#text-spinner li:nth-child(5) { animation-delay: 8s}
#text-spinner li:nth-child(6) { animation-delay: 10s}
#text-spinner li:nth-child(7) { animation-delay: 12s}
/* #text-spinner li:nth-child(8) { animation-delay: 14s} */

@keyframes cycle {
    0% {
        transform: translateY(3.5rem);
        opacity: .25;
    }
    6%, 12%  {
        transform: translateY(0);
        opacity: 1;
    }
    18%, 100% {
        transform: translateY(-3.5rem);
        opacity: 0;
    }   
}
</style>