<template>
    <div class="entry-filter">
        <!-- <span class="check"><IconCheck/></span> -->
        <!-- <span class="plus"><IconPlus/></span> -->
        <span class="text"><slot></slot></span>
    </div>
</template>

<script>
// import IconCheck from "../icons/IconCheck.vue";
// import IconPlus from "../icons/IconPlus.vue";

export default {
    name: 'EntryFilter',
    components: {
        // IconCheck,
        // IconPlus,
    },
    props: {
        href: {
            type: String,
            required: true,
        }
    },
}
</script>