<template>
    <div id="panel-2" class="active inner mt-[60px] pl-[6.5rem] pr-[6.938rem] !justify-start">
        <div class="wrapper flex flex-wrap justify-between">
            <div class="column basis-full">
                <h2 class="heading text-[3rem] font-proxima text-secondary-10 font-light leading-none mb-[1.688rem]">
                    {{ data.page_3_title }}</h2>
                <div class="text-wrap">
                    <p class="mb-3.5 text-white">{{ data.page_3_text }}</p>
                </div>
            </div>
        </div>
        <div class="panel-topics-themes mb-[12rem]  sh-lg:mb-[9rem] overflow-y-auto">
            <Subject v-for="theme in themeOptions"
                     :key="localId(theme.theme_api_id)"
                     :isSelected="this.isSelected(localId(theme.theme_api_id))"
                     @click="setTheme(localId(theme.theme_api_id))">
                <span class="text">{{ theme.theme_name }}</span>
                <img
                     class="subject-image -rotate-[5deg]"
                     :src="theme.image_url"
                     :alt="theme.theme_name" />
            </Subject>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Subject from "../buttons/Subject.vue";

export default {
    name: "PanelTwo",
    props: { data: Object },
    components: {
        Subject,
    }, computed: {
        ...mapGetters({
            'selected_themes': 'timeline/selected_themes',
            'themes': 'filters/themes',
        }),
        themeOptions () {
            return this.data.theme_selection;
        }
    },
    methods: {
        isSelected(id) {
            return this.selected_themes.includes(id);
        },
        localId(id) {
            let theme = this.themes.find(t => t.id == id)
            return `theme-${theme.key}`
        },
        setTheme(id) {
            if (this.isSelected(id)) {
                this.$store.dispatch('timeline/removeSelectedTheme', id)
            }
            else {
                this.$store.dispatch('timeline/clearSelectedThemes')
                this.$store.dispatch('timeline/addSelectedTheme', id)
            }
        }
    }
}
</script>