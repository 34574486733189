<template>
    <section>
        <ul class="years" :class="columnClass" v-if="!loading">
             <template v-if="showTI">
                <YearNumber
                    v-for="(year, idx) in ti_years" 
                    :key="`ti-${idx}`"
                    :text="year"
                    :year="idx"
                    :isTI="true"
                    :showYear="showYear(idx, true)"
                    :showLine="showDivider(idx)">
                </YearNumber>
             </template>
            <YearNumber
                v-for="(year, idx) in tl_years" 
                :key="year"
                :year="year"
                :showYear="showYear(idx)"
                :showLine="showDivider(idx)">
            </YearNumber>
        </ul>
        <ul v-else class="years" :class="columnClass">
            <YearNumber
                v-for="(year, idx) in loadingYears" 
                :key="year"
                :year="year"
                :showYear="showYear(idx)"
                :showLine="showDivider(idx)">
            </YearNumber>
        </ul>
        <ul class="eras" :class="[columnClass, {'pl-[48.375rem]': showTI, 'pl-[3.375rem]': !showTI, 'invisible': loading}]">
            <template v-for="(e, idx) in eraList" :key="e.title">
            <li v-if="showStartSeparator(e, idx)">
                <span class="separator"></span>
            </li>
            <li :style="`width: ${eraWidth(e)}`" 
                class="era-text" 
                :class="{'make-stick': isWide(e)}">
                <span class="text" :style="`width: ${eraWidth(e)}`" >
                    {{ e.title }}
                </span>
            </li>
            <li v-if="isEnd(e)">
                <span class="separator"></span>
            </li>
            </template>
        </ul>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ZoomMixin from '../../mixins/ZoomMixin'
import YearNumber from './YearNumber'

export default {
    name: 'YearsTimeline',
    mixins: [ZoomMixin],
    components: {
        YearNumber,
    },
    computed: {
        ...mapGetters({
            unchangedEras: 'global/eras',
            eras: 'timeline/tl_eras',
            tl_years: 'timeline/tl_years',
            centerYear: 'timeline/centerYear',
            showTI: 'timeline/showTI',
            loading: 'records/loading',
        }),
        eraList () {
            let eras = [...this.eras]
            return eras.sort((a,b) => (a.start_year > b.start_year) ? 1 : ((b.start_year > a.start_year) ? -1 : 0))
        },
        ti_years () {
            let tiYrs = []
            for( let i = 0; i < this.zoom_level.sectionColumns * 3; i++) {
                tiYrs.push('Human history in Oregon before 1680')
            }
            return tiYrs
        },
        loadingYears () {
            let tempYears = [...Array(1840).keys()]
            return tempYears.slice(1680);
        }
    },
    methods: {
        showYear (i, isTI) {
            if (isTI) {
                return i == this.zoom_level.sectionColumns;
            } else {
                return i % this.zoom_level.sectionColumns == 0;
            }
        },
        showDivider (i) {
            let numCols = this.zoom_level.sectionColumns / this.zoom_level.yearColumns;
            return i % numCols == 0;
        },
        eraWidth (era) {
            let w = Math.abs((era.start_year - 1) - era.end_year);
            return `${w * this.columnWidth}px`;
        }, 
        isWide (era) {
            let w = Math.abs((era.start_year - 1) - era.end_year)  * (this.columnWidth + 1);
            return w >= window.innerWidth;
        },
        isEnd(era) {
            let originalEra = this.unchangedEras.find( e => era.title == e.title)
            return era.end_year == originalEra.end_year;
        },
        showStartSeparator(era, idx) {
            return this.showTI && !era.title.includes('Ancient') && idx == 0;
        }
    },
    updated () {
        if (this.centerYear) {
            let c = document.querySelector(`#yr-${this.centerYear}`)
            if (c) {
                let newCenter = c.offsetLeft - window.innerWidth/2;
                this.$emit('scrollToCenter', newCenter)
            }
        }
    }
}
</script>
