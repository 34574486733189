 export const state = () => ({
     intro_content: [],
     intro_current_panel: 0,
     curated_timelines: [],
     selected_curated_timeline: {},
     milestones_open: false,
     eras: [],
     ui_text: {},
     share_modal_open: false,
     filter_panel_open: false,
     about_panel_open: false,
     cache: false,
     share_url: '',
     need_apply_fiters: false,
     showLearnTooltip: false,
     curated_timeline_span: {},
     mobile: false,
     is_kiosk: false,
 })


export const actions = {
    async fetchIntroContent({commit}) {
        const response = await fetch(`${process.env.VUE_APP_OE_API_URL}/api/timeweb/intro/1/?api_token=${process.env.VUE_APP_API_TOKEN}`);
        const data = await response.json();
        commit('setIntroContent', data)
    },
    async fetchCuratedTimelines({commit,dispatch}) {
        const response = await fetch(`${process.env.VUE_APP_OE_API_URL}/api/timeweb/timeline/?fields=title,timeline_records`);
        const data = await response.json();
        commit('setCuratedTimelines', data.items)
        dispatch('setCuratedTimeline', data.items[0])

        let years = data.items.map(tl => {
            return tl.timeline_records.map(r => {
                let yr = ''
                if (r.year) {
                    yr = r.year.match(/(?:[0-9]{4})/g) ? parseInt(r.year.match(/(?:[0-9]{4})/g)[0]) : ''
                    if (r.year == 'TI' || r.year.includes('before 1680')) {
                        yr = 0
                    }
                }
                return yr;
            })
        })
        
        let sorted = years.flat().sort((a, b) => {
            return a - b
        }).filter(y => {
           return !!y;
        })

        commit('setCuratedTimelineSpan', {
            first_year: sorted[0],
            last_year: sorted.pop()
        });
    },
    async setCuratedTimeline({commit}, data) {
        const response = await fetch(`${process.env.VUE_APP_OE_API_URL}/api/timeweb/timeline/${data.id}/?api_token=${process.env.VUE_APP_API_TOKEN}`);
        const result = await response.json();

        commit('setSelectedCuratedTimeline', result);
    },
    async fetchEras({commit, dispatch}) {
        const response = await fetch(`${process.env.VUE_APP_OE_API_URL}/metadata/era/?api_token=${process.env.VUE_APP_API_TOKEN}`);
        const data = await response.json();
        let eras = []
        data.forEach(e => {
            let era = {};

            let split = e.title.split('(')
            let title = split[1] ? split[1].substring(0, split[1].length - 1) : split[0];
            let years = e.slug.split('_')[0]
            
            era['title'] = title;

            if (title.includes('Ancient')) {
                era['start_year'] = 0;
                era['end_year'] = 1599;
            } else if (title.includes('before')) {
                era['start_year'] = 0;
                era['end_year'] = 1791;
            } else if (years) {
                let span = years.split('-');
                era['start_year'] = parseInt(span[0]);
                era['end_year'] = span[1] == 'present' ? 2030 : parseInt(span[1]);
            }

            eras.push(era)
        })

        await commit('setEras', eras)
        dispatch('timeline/setEras', {}, {root: true});
    },
    async fetchUIText({commit}) {
        const data = await require("@/assets/data/index.json");
        commit('setUIText', data);
    },
    shareModalToggle({commit, state}) {
        if (state.about_panel_open) {
            commit('setAboutPanelOpen', false)
        }
        if (state.filter_panel_open) {
            commit('setFilterPanelOpen', false)
        }
        commit('setShareUrl', window.location.href);
        commit('setShareModalOpen', !state.share_modal_open)
    },
    filterPanelToggle({commit, state}) {
        if (state.about_panel_open) {
            commit('setAboutPanelOpen', false)
        }
        if (state.share_modal_open) {
            commit('setShareModalOpen', false)
        }
        commit('setFilterPanelOpen', !state.filter_panel_open)
    },
    aboutPanelToggle({commit, state}) {
        if (state.filter_panel_open) {
            commit('setFilterPanelOpen', false)
        }
        if (state.share_modal_open) {
            commit('setShareModalOpen', false)
        }
        commit('setAboutPanelOpen', !state.about_panel_open)
    },
    setCurrentPanel({commit}, value) {
        commit('setIntroPanel', value)
    },
    closeAllPanels({commit}){
        commit('setShareModalOpen', false)
        commit('setFilterPanelOpen', false)
        commit('setAboutPanelOpen', false)
    },
    milestonesToggle({commit, state}) {
        commit('setMilestonesOpen', !state.milestones_open)
    },
    useCache ({commit}, value) {
        commit('setCacheStatus', value)
    },
    setApplyFilters ({commit}, value) {
        commit('setApplyFilters', value)
    },
    setShowLearnTooltip ({commit}, value) {
        commit('showLearnTooltip', value)
    },
    checkIfMobile({ commit }) {
        const isMobile = () => {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
        }
        if (isMobile()) {
            commit('setMobile', true)
        }
    },
}

 export const mutations = {
     setIntroContent(state, data) {
         state.intro_content = data
     },
     setCuratedTimelines(state, data) {
         state.curated_timelines = data
     },
     setCuratedTimelineSpan(state, data) {
        state.curated_timeline_span = data
     },
     setSelectedCuratedTimeline(state, data) {
         state.selected_curated_timeline = data
     },
     setEras(state, data) {
         state.eras = data
     },
     setUIText(state, data) {
         state.ui_text = data
     },
     setShareModalOpen(state, value) {
         state.share_modal_open = value
     },
     setFilterPanelOpen(state, value) {
         state.filter_panel_open = value
     },
     setAboutPanelOpen(state, value) {
         state.about_panel_open = value
     },
     setIntroPanel(state, value) {
         state.intro_current_panel = value
     },
     setMilestonesOpen(state, value) {
         state.milestones_open = value
     },
     setCacheStatus(state, value) {
         state.cache = value
     },
     setShareUrl(state, value) {
         state.share_url = value
     },
     setApplyFilters(state, value) {
         state.need_apply_fiters = value
     },
     showLearnTooltip(state, value) {
         state.show_learn_tooltip = value
     },
     setMobile(state, data) {
        state.mobile = data
    },
    setIsKiosk(state, data) {
        state.is_kiosk = data
    }
 }

 export const getters = {
     intro_content(state) {
         return state.intro_content;
     },
     curated_timelines(state) {
         return state.curated_timelines;
     },
     selected_curated_timeline(state) {
         return state.selected_curated_timeline;
     },
     eras(state) {
         return state.eras;
     },
     ui_text(state) {
         return state.ui_text;
     },
     share_modal_open(state) {
         return state.share_modal_open;
     },
     filter_panel_open(state) {
         return state.filter_panel_open;
     },
     about_panel_open(state) {
         return state.about_panel_open;
     },
     intro_current_panel(state) {
         return state.intro_current_panel;
     },
     milestones_open(state) {
         return state.milestones_open;
     },
     share_url(state) {
        return state.share_url;
     },
     need_apply_fiters(state) {
        return state.need_apply_fiters;
     },
     show_learn_tooltip(state) {
        return state.show_learn_tooltip;
     },
     curated_timeline_span(state) {
        return state.curated_timeline_span;
     },
     mobile(state) {
        return state.mobile;
     },
     is_kiosk(state) {
        return state.is_kiosk;
     },
 }

 export default {
     namespaced: true,
     state,
     getters,
     actions,
     mutations
 }