<template>
    <div class="panel-footer flex flex-wrap items-center justify-between bg-shades-20 z-20">
        <PanelPagination :page="currentPanel" @go-to="goToPage"/>
        <div class="button-wrap flex items-center gap-x-[1.938rem] z-50">
            <a href="#panel-next" 
               class="next-panel button btn-gold"
                v-if="currentPanel !== 3"
               @click="this.$emit('nextPanel')">
                <span class="text">{{ buttons.next }}</span>
            </a>
            <button
                v-if="currentPanel == 3"
                class="close-panel button explore btn-gold"
                @click="search">
                <span class="text">{{ buttons.explore }}</span>
            </button>
            <router-link v-else :to="{name: 'timeline'}"
                id="panel-close"
                class="close-panel button close btn-link">
                <span class="text text-neutral-20 hover:text-white">{{ buttons.close }}</span>
            </router-link> 
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PanelPagination from "../buttons/PanelPagination.vue";

export default {
    name: "PanelFooter",
    props: { 
        buttons: Object,
        currentPanel: Number, 
    },
    components: {
        PanelPagination,
    },
    computed: {
        ...mapGetters({
            'search_obj': 'timeline/search_obj',
            'search_string': 'timeline/search_string',
        })
    },
    methods: {
        goToPage(page) {
            this.$store.dispatch('global/setCurrentPanel', page) 
        },
        async search () {
            await this.$store.dispatch('records/search', this.search_obj);

            if (this.search_string.length) {
                this.$router.push(`/timeline${this.search_string}`)
            } else {
                this.$router.push(`/timeline`)
            }
        }
    }
}
</script>
