<template>
    <ul ref="group"
        key="group"
        class="image-group-list" 
        :class="{stack: stack}" 
        :style="`transform: translateY(${translateValue(listI, true)}px) rotate(${rotateValue(listI, true)}deg)`"
        @click="zoom">
        <template v-for="(result, i) in results" :key="result.slug">
            <li v-if="recordStyle == 'curated'"
                class="image-wrap milestone-info"
                >
                <Milestone 
                    :stacked="stack"
                    :record="result">
                </Milestone>
            </li>
            <li v-else-if="displayEntryImage(i)" 
                class="image-wrap"
                :style="`transform: translateY(${translateValue(i)}px)`">
                <div class="entry-image-wrap">
                    <EntryImage
                                :stacked="stack"
                                :style="`transform: rotate(${rotateValue(i)}deg)`"
                                href="#"
                                :record="result">
                    </EntryImage>
                </div>
            </li>
        </template>
    </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import EntryImage from "../buttons/EntryImage.vue";
import Milestone from "../buttons/Milestone.vue";
import ZoomMixin from '../../mixins/ZoomMixin'

export default {
    name: 'ImageGroup',
    props: {
        results: {
            type: Array,
            required: true,
        },
        recordStyle: {
            type: String,
            default: 'result'
        },
        listI: Number
    },
    data () {
        return {
            expanded: false
        }
    },
    computed: {
        ...mapGetters({
            'detail': 'records/detail',
            'zoom_level': 'timeline/zoom_level',
            'milestonesOpen': 'global/milestones_open',
        }),
        stack () {
            let max = 5;
            if (this.recordStyle == 'curated') max = 3;
            return this.results.length >= max && !this.isFullyZoomed;
        },
        spanYear () {
            let year = 1800;
            if (this.results.length) {
                if (this.recordStyle == 'result') {
                    year = this.results[0].date.match(/(?:[0-9]{4})/g)[0]
                } else {
                    year = this.results[0].year.match(/(?:[0-9]{4})/g)[0]
                }
            }
            return year;
        }
    },
    mixins: [ZoomMixin],
    components: {
        EntryImage,
        Milestone,
    },
    methods: {
        async zoom () {
            if (this.stack) {
                await this.$store.dispatch('timeline/setCenterYr', this.spanYear)
                this.$store.dispatch('timeline/zoomIn');
            }
        },
        translateValue(i, isParentList = false) {
            let applyStyles = true;
            if (this.recordStyle !== 'result') applyStyles = false
            if (this.isFullyZoomed) {
                if (isParentList && this.stack) applyStyles = false
            } else {
                if (isParentList && !this.stack) applyStyles = false
            }

            if (!applyStyles ) return 0;

            let wHeight = this.milestonesOpen ? window.innerHeight - 425 : window.innerHeight - 250;
            let numResults = this.results.length >= 10 ? this.results.length * 1.1 : this.results.length * 15;
            let zoomedInOffset = Math.max(Math.floor(wHeight / numResults), 25);

            let spacing = this.isFullyZoomed ? zoomedInOffset : 25;
            if (!this.stack || this.isFullyZoomed) {
                return i * spacing;
            } else {
                return 0;
            }
        },
        rotateValue(i, isParentList = false) {
            let applyStyles = true;
            if (this.recordStyle !== 'result') applyStyles = false
            if (this.isFullyZoomed) {
                if (isParentList && this.results.length > 5) applyStyles = false
            }

            if (!applyStyles) return 0;
            let leanDirections = {
                'left': [7, -8, 5, -5],
                'right': [-7, 5, -5, 8],
            }
            let random = this.listI + Math.floor((Math.random() * 20))
            let direction = random % 2 == 0 ? 'left' : 'right';

            return i % 2 === 0 ? leanDirections[direction][0]
                : i % 3 === 0 ? leanDirections[direction][1]
                : i % 5 === 0 ? leanDirections[direction][2]
                : leanDirections[direction][3]
        },
        isSelected(record) {
            if (!this.detail) return false;
            return this.detail.id == record.id
        },
        displayEntryImage (i) {
            if (this.zoom_level.level == 4) return this.recordStyle == 'result';
            return this.recordStyle == 'result' && i < 6;
        }

    },
    mounted () {
        if (this.recordStyle == 'result') this.emitter.emit("mounted-groups", this.$refs.group);
    }
}
</script>