<template>
    <nav id="nav-bar" class="w-[3.375rem] bg-neutral-60">
        <div class="site-title">
            <span class="text-white" @click="resetZoom">
                <span class="sh:hidden">Oregon Historical Society |</span>
                <span class="tl:hidden">OHS |</span>
                TimeWeb
            </span>
        </div>
        <div class="nav-bar-links">
            <!-- Intro Button -->
            <button
                    class="hover-fill"
                    ref="introButton"
                    :class="{ 'active': this.$route.name == 'landing-page' }"
                    :aria-pressed="this.$route.name == 'landing-page'"
                    @click="toggleIntroPanel"
                    @mouseover="handleMouseEnter('intro-tooltip')"
                    @mouseleave="handleMouseLeave('intro-tooltip')">
                <IntroIcon />
                <span class="sr-only">{{ tooltips.intro }}</span>
            </button>
            <div
                 id="intro-tooltip"
                 ref="introTooltip"
                 role="tooltip"
                 :class="['tooltip nav-tooltip', hideTooltips]">
                <div class="tooltip-content">
                    {{ tooltips.intro }}
                </div>
            </div>
            <!-- Filters Button -->
            <button
                    class="hover-fill"
                    ref="filterButton"
                    :aria-pressed="filterPanelOpen"
                    aria-controls="filters"
                    :class="{ 'active': filterPanelOpen }"
                    @click="toggleFilterPanel"
                    @mouseover="handleMouseEnter('filter-tooltip')"
                    @mouseleave="handleMouseLeave('filter-tooltip')">
                <FilterIcon />
                <span class="sr-only">{{ tooltips.filters }}</span>
            </button>
            <div
                 id="filter-tooltip"
                 ref="filterTooltip"
                 role="tooltip"
                 :class="['tooltip nav-tooltip', hideTooltips]">
                <div class="tooltip-content">
                    {{ tooltips.filters }}
                </div>
            </div>
            <!-- Timeline Button -->
            <button
                    class="hover-stroke"
                    ref="timelineButton"
                    :aria-pressed="this.$route.name == 'timeline' && !filterPanelOpen && !aboutPanelOpen"
                    :class="{ 'active': this.$route.name == 'timeline' && !filterPanelOpen && !aboutPanelOpen }"
                    @click="showTimeline"
                    @mouseover="handleMouseEnter('timeline-tooltip')"
                    @mouseleave="handleMouseLeave('timeline-tooltip')">
                <span class="sr-only"> {{ tooltips.timeline }}</span>
                <TimelineIcon />
            </button>
            <div
                 id="timeline-tooltip"
                 ref="timelineTooltip"
                 role="tooltip"
                 :class="['tooltip nav-tooltip', hideTooltips]">
                <div class="tooltip-content">
                    {{ tooltips.timeline }}
                </div>
            </div>
            <!-- About Button -->
            <button
                    class="hover-stroke"
                    id="about-btn"
                    ref="aboutButton"
                    :aria-pressed="aboutPanelOpen"
                    :class="{ 'active': aboutPanelOpen }"
                    @click="toggleAboutPanel"
                    @mouseover="handleMouseEnter('about-tooltip')"
                    @mouseleave="handleMouseLeave('about-tooltip')">
                <InfoIcon />
                <span class="sr-only">About</span>
            </button>
            <!-- <Transition name="fade"> -->
            <div
                    id="about-tooltip"
                    ref="learnTooltip"
                    role="tooltip"
                    :class="['tooltip nav-tooltip', hideTooltips]">
                <div class="tooltip-content">
                    {{ tooltips.learn }}
                </div>
            </div>
            <!-- </Transition> -->
        </div>
        <div id="curatedtooltip" ref="curatedPlacement">
            <div
                 ref="curatedTimelineTooltip"
                 id="curated-tooltip"
                 class="tooltip bottom"
                 :class="aboutPanelOpen ? 'show-tooltips' : 'hide-tooltips'"
                 role="tooltip">
                <div class="tooltip-content">
                    {{ tooltips.companion_timeline }}
                </div>
            </div>
        </div>
    </nav>
</template> 

<script>
import { mapGetters } from 'vuex'
import { createPopper } from '@popperjs/core'

import FilterIcon from "./icons/IconFilter.vue";
import InfoIcon from "./icons/IconInfo.vue";
import IntroIcon from "./icons/IconIntro.vue";
import TimelineIcon from "./icons/IconTimeline.vue";

export default {
    name: 'NavBar',
    components: {
        FilterIcon,
        InfoIcon,
        IntroIcon,
        TimelineIcon,
    },
    computed: {
        ...mapGetters({
            'filterPanelOpen': 'global/filter_panel_open',
            'aboutPanelOpen': 'global/about_panel_open',
            'shareModalOpen': 'global/share_modal_open',
            'milestonesOpen': 'global/milestones_open',
            'ui_text': 'global/ui_text',
            'showLearnTooltip': 'global/show_learn_tooltip',
            'loading': 'records/loading',
            'search_string': 'timeline/search_string',
        }),
        tooltips() {
            if (!this.ui_text.about) return {}
            return this.ui_text.about.tooltips
        },
        hideTooltips() {
            return {
                'hide-tooltips': !this.aboutPanelOpen,
            }
        },
        showLearn() {
            return !this.loading && this.$route.name == 'timeline' && this.showLearnTooltip;
        }
    },
    methods: {
        tooltipPopper(button, tooltip, placement, offset) {
            const popper = createPopper(button, tooltip, {
                placement: placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: offset,
                        },
                    },
                ],
            })
            return () => popper
        },
        toggleFilterPanel() {
            this.$store.dispatch('global/filterPanelToggle');
            this.returnToTimeline();
        },
        toggleAboutPanel() {
            if ((!this.aboutPanelOpen && !this.milestonesOpen) || (this.aboutPanelOpen && this.milestonesOpen)) {
                this.toggleMilestones();
            }
            this.$store.dispatch('global/aboutPanelToggle');
            this.returnToTimeline();
        },
        toggleShareModal() {
            this.$store.dispatch('global/shareModalToggle');
            this.returnToTimeline();
        },
        toggleIntroPanel() {
            this.$store.dispatch('global/closeAllPanels');
            if (this.$route.name == 'landing-page') {
                this.$router.push(`/timeline${this.search_string}`)
            } else {
                this.$router.push(`/${this.search_string}`)
            }
        },
        showTimeline() {
            this.$store.dispatch('global/closeAllPanels');
            this.returnToTimeline();
        },
        returnToTimeline() {
            if (this.$route.name != 'timeline') {
                this.$router.push(`/timeline${this.search_string}`)
            }
        },
        toggleMilestones() {
            this.$store.dispatch('global/milestonesToggle')
        },
        resetZoom() {
            this.$store.dispatch('timeline/zoomReset')
        },
        handleMouseEnter(id) {
            const el = document.getElementById(id)
            if (el && !this.aboutPanelOpen) {
                el.classList.add('tooltip-hover');
            }
        },
        handleMouseLeave(id) {
            const el = document.getElementById(id)
            if (el && !this.aboutPanelOpen) {
                el.classList.remove('tooltip-hover');
            }
        },
    },
    mounted() {
        this.tooltipPopper(this.$refs.filterButton, this.$refs.filterTooltip, 'left', [-18, 18])
        this.tooltipPopper(this.$refs.introButton, this.$refs.introTooltip, 'left', [-18, 18])
        this.tooltipPopper(this.$refs.timelineButton, this.$refs.timelineTooltip, 'left', [-18, 18])
        this.tooltipPopper(this.$refs.aboutButton, this.$refs.learnTooltip, 'left', [-18, 18])
        this.tooltipPopper(this.$refs.curatedPlacement, this.$refs.curatedTimelineTooltip, 'left', [-125, -18])
    }
}
</script>